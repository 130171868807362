import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { setHours, setMinutes } from "date-fns";

// style
import "react-datepicker/dist/react-datepicker.min.css";
import clsx from "clsx";
import { Control, Controller, Path } from "react-hook-form";
import style from "./SelectTime.module.scss";

// plugin

// テキストボックス
type SelectTimeProps<T> = {
  name: Path<T>;
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
  register?: object;
  control: Control<T>;

  // エラー
  error?: {
    type?: string; // 使わない
    message?: string; // これを表示
  };
};

export const SelectTime = <T,>({
  name,
  error,
  startHour,
  startMinutes,
  endHour,
  endMinutes,
  control,
}: SelectTimeProps<T>) => {
  registerLocale("ja", ja);

  return (
    <>
      {error && <p className={style.errorText}>{error.message}</p>}

      <div className={style.wrapper}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value as Date}
              onChange={onChange}
              className={clsx(style.input, error && style.isError)}
              calendarClassName={style.time}
              popperModifiers={[
                {
                  name: "arrow",
                  options: {
                    padding: ({ popper, reference }) => ({
                      right: Math.min(popper.width, reference.width) - 40,
                    }),
                  },
                },
              ]}
              name={name}
              locale="ja"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              minTime={setHours(
                setMinutes(new Date(), startMinutes),
                startHour
              )}
              maxTime={setHours(setMinutes(new Date(), endMinutes), endHour)}
            />
          )}
        />
      </div>
    </>
  );
};
