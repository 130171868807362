import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoggedInFlagProvider from "./components/providers/LoggedInFlagProvider";
import SelectedUserCarIdProvider from "./components/providers/SelectedUserCarIdProvider";
import { Toast } from "./components/common/Message/Message";
import { SessionProvider } from "./components/hooks/useSession";
import "./css/base.css";
import "./index.css";
import { NotFound } from "./page/error/404";
import InquiryEdit from "./page/inquiry/edit";
import Login from "./page/login/login";
import MypageTop from "./page/mypage/top";
import PasswordForgetPage from "./page/password/forget";
import PasswordSettingPage from "./page/password/setting";
import RegisterEdit from "./page/register/edit";
import RegisterLogin from "./page/register/login";
import RegisterComplete from "./page/register/complete";
import UseinfoEdit from "./page/useinfo/edit";
import UseinfoSummary from "./page/useinfo/summary";
import UserinfoPassword from "./page/userinfo/password";
import UserinfoEmailEdit from "./page/userinfo/email-edit";
import UserInfoEmailConfirm from "./page/userinfo/email-confirm";
import reportWebVitals from "./reportWebVitals";
import MaintenanceScheduleEdit from "./page/maintenance_schedule/edit";
import { Contract } from "./page/contract";

const main = () => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(<App />);
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

const App = () => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <Toast type="success" id="success" />
        <Toast type="error" id="error" />
        <AppRouter />
        <ReactQueryDevtools />
      </SessionProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

const AppRouter = () => {
  const [loggedInFlag, setLoggedInFlag] = useState(false);
  const setLoggedIn = useCallback((flag: boolean) => setLoggedInFlag(flag), []);

  const [selectedUserCarId, setSelectedUserCarId] = useState("1");
  const setSelectedUserCar = useCallback(
    (carId: string) => setSelectedUserCarId(carId),
    []
  );

  return (
    <LoggedInFlagProvider loggedInFlag={loggedInFlag} setLoggedIn={setLoggedIn}>
      <SelectedUserCarIdProvider
        selectedUserCarId={selectedUserCarId}
        setSelectedUserCarId={setSelectedUserCar}
      >
        <BrowserRouter>
          <div>
            <Routes>
              <Route path="/" element={<MypageTop />} />
              <Route path="/password/forget" element={<PasswordForgetPage />} />
              <Route
                path="/password/setting/:token"
                element={<PasswordSettingPage />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/userinfo/edit" element={<UseinfoEdit />} />
              <Route path="/userinfo/summary" element={<UseinfoSummary />} />
              <Route path="/userinfo/password" element={<UserinfoPassword />} />
              <Route path="/userinfo/email" element={<UserinfoEmailEdit />} />
              <Route
                path="/maintenance_schedule/edit"
                element={<MaintenanceScheduleEdit />}
              />
              <Route
                path="/userinfo/email/:token"
                element={<UserInfoEmailConfirm />}
              />
              <Route path="/inquiry/edit" element={<InquiryEdit />} />
              <Route path="/register/edit/:id" element={<RegisterEdit />} />
              <Route path="/register/login/:id" element={<RegisterLogin />} />
              <Route path="/register/complete" element={<RegisterComplete />} />
              <Route path="/contract" element={<Contract />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </BrowserRouter>
      </SelectedUserCarIdProvider>
    </LoggedInFlagProvider>
  );
};

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
