// style
import style from "./Footer.module.scss";

export function Footer() {
  return (
    <header className={style.footer}>
      <p className={style.copyright}>
        ©︎ Premium Group Co., Ltd. All Rights Reserved.
      </p>
    </header>
  );
}
