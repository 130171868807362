import { useContext } from "react";
import clsx from "clsx";

// plugin
import { useLocation } from "react-router-dom";

// カスタムhooks
import { useUserCars } from "../../hooks/useUserCars";

import { OfferSession, useOfferSession } from "../../hooks/useOfferSession";
import { useOfferQuery } from "../../hooks/useOfferQuery";

// providers
import { SelectedUserCarIdContext } from "../../providers/SelectedUserCarIdProvider";

// style
import style from "./Logo.module.scss";

type Props = {
  contractType?: string; // この値が存在する場合、Brandの判定時この値を優先的に使用する.
};

export function Logo({ contractType }: Props) {
  // リクエストしたURLを取得
  const location = useLocation();

  // 申込情報(Offer)を取得
  const useOffer = (
    session: OfferSession | null,
    options?: Parameters<typeof useOfferQuery>[1]
  ) => {
    const query = useOfferQuery(session, { staleTime: Infinity, ...options });
    return { query, offer: query.data };
  };

  const session = useOfferSession();

  // 申込情報入力の画面のロゴの出しわけで使用する契約種別
  const registerEditContractType = useOffer(session).offer?.contract_type;

  // 車両情報
  const { userCars } = useUserCars();

  // 選択された車両
  const { selectedUserCarId } = useContext(SelectedUserCarIdContext);
  const selectedCar = userCars.find((uc) => uc.value === selectedUserCarId);

  // 選択された車両の会社名を出し分ける関数(マイページとお問合せ画面)
  type Brand = "CarPremium" | "CarSensor" | undefined;
  type IsBrand = () => Brand;

  const isBrand: IsBrand = (): Brand => {
    let brand: Brand;
    const contractBrand = contractType || selectedCar?.contract_type;
    if (contractBrand === "car_premium") {
      brand = "CarPremium";
    } else if (contractBrand === "car_sensor") {
      brand = "CarSensor";
    } else {
      brand = undefined;
    }
    return brand;
  };

  const isRegisterBrand: IsBrand = (): Brand => {
    let brand: Brand;
    const contractBrand = contractType || registerEditContractType;
    if (contractBrand === "car_premium") {
      brand = "CarPremium";
    } else if (contractBrand === "car_sensor") {
      brand = "CarSensor";
    } else {
      brand = undefined;
    }
    return brand;
  };

  // プレミア社のロゴ
  const PremiumLogoUrl: string[] = [
    // ログイン画面はプレミアのロゴ
    "/login",
    // パスワード再設定画面はプレミアのロゴ
    "/password/setting",
    // パスワード忘れ画面はプレミアのロゴ
    "/password/forget",
  ];

  // Null場合のロゴ
  const NullLogoUrl: string[] = [
    // 会員情報編集の時はロゴを表示しない
    "/userinfo/edit",
    // 会員情報詳細の時はロゴを表示しない
    "/userinfo/summary",
  ];

  return (
    <>
      {(() => {
        // マイページ、メンテ候補日選択、お問い合わせ
        if (
          location.pathname === "/" ||
          location.pathname === "/maintenance_schedule/edit" ||
          location.pathname === "/inquiry/edit"
        ) {
          return (
            (isBrand() === "CarSensor" && (
              <div className={clsx(style.commonFlame, style.flame)}>
                <img
                  src="/images/logo/recruit-one-liner.png"
                  alt="CarSensor"
                  className={style.recruitLogo}
                />
              </div>
            )) ||
            (isBrand() === "CarPremium" && (
              <div className={clsx(style.commonFlame, style.narrowFlame)}>
                <img
                  src="/images/logo/premium.png"
                  alt="CarPremium"
                  className={style.premiumLogo}
                />
                <p className={style.title}>カープレミア安心メンテパック</p>
              </div>
            )) ||
            undefined
          );
        }
        // 申込情報入力
        if (
          location.pathname.match(/register\/login/) ||
          location.pathname.match(/register\/edit/) ||
          location.pathname === "/register/complete"
        ) {
          return (
            (isRegisterBrand() === "CarSensor" && (
              <div className={clsx(style.commonFlame, style.flame)}>
                <img
                  src="/images/logo/recruit-one-liner.png"
                  alt="CarSensor"
                  className={style.recruitLogo}
                />
              </div>
            )) ||
            (isRegisterBrand() === "CarPremium" && (
              <div className={clsx(style.commonFlame, style.narrowFlame)}>
                <img
                  src="/images/logo/premium.png"
                  alt="CarPremium"
                  className={style.premiumLogo}
                />
                <p className={style.title}>カープレミア安心メンテパック</p>
              </div>
            )) ||
            undefined
          );
        }
        // ログイン/パスワード忘れ/パスワード再設定
        if (
          PremiumLogoUrl.some((path) => location.pathname.indexOf(path) > -1)
        ) {
          return (
            <div className={clsx(style.commonFlame, style.narrowFlame)}>
              <img
                src="/images/logo/premium.png"
                alt="CarPremium"
                className={style.premiumLogo}
              />
            </div>
          );
        }
        // ロゴを表示しない場合
        if (NullLogoUrl.some((path) => location.pathname.indexOf(path) > -1)) {
          return (
            <div
              className={clsx(style.commonFlame, style.flame, style.noLogo)}
            />
          );
        }
      })()}
    </>
  );
}
