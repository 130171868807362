import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import {
  FormData,
  FormGroup,
  FormSubGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import formStyle from "../../components/form/Form/Form.module.scss";
import { InputText } from "../../components/form/InputText/InputText";
import { Select } from "../../components/form/Select/Select";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { usePrefectureOptions } from "../../components/hooks/usePrefecturesQuery";
import { useProfileQuery, User } from "../../components/hooks/useProfileQuery";
import { Session, useSession } from "../../components/hooks/useSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { ServerError } from "../error/500";
import { validations } from "../../components/validates/validates";

export default function Page() {
  useLoginCheck();
  const session = useSession();
  const navigate = useNavigate();
  const { data, error } = useProfileQuery(session, { staleTime: Infinity });

  if (error) {
    console.error(error);
    // 謎のエラー
    if (!axios.isAxiosError(error)) {
      return <ServerError />;
    }
    // ネットワークエラー
    if (!error.response) {
      return <ServerError />;
    }
    // 認証エラー
    if (error.response.status === 401) {
      navigate("/login");
      return null;
    }
    // その他
    return <ServerError />;
  }
  if (!data) {
    return null;
  }
  return <UserinfoEdit user={data} />;
}

type FormValues = {
  phone: string;
  tel: string;
  fax: string;
  zip: string;
  pref: string;
  addr: string;
  addr_other: string;
  addr_ap: string;
};

const useProfileMutation = () => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/profile`;
  type Variables = { session: Session; values: FormValues };
  return useMutation<
    User,
    AxiosError<Record<"message" | "type" | "name", string>[]>,
    Variables
  >([url], async ({ session, values }) => {
    const { data } = await axios.patch<User>(url, values, {
      headers: {
        "access-token": session.accessToken,
        client: session.client,
        uid: session.uid,
      },
    });
    return data;
  });
};

function UserinfoEdit({ user }: { user: User }) {
  const session = useSession();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: user,
  });
  const { prefectures } = usePrefectureOptions();
  const navigate = useNavigate();
  const mutation = useProfileMutation();

  if (!session) return null;

  return (
    <SiteLayout>
      <Heading1>会員情報編集</Heading1>
      <Text>
        {`変更したい情報を編集し、登録ボタンを押してください。\nお名前を変更したい場合は、お問い合わせフォームからの申請をお願いいたします。`}
      </Text>

      <Inner>
        <form
          className={formStyle.form}
          onSubmit={handleSubmit(async (values) => {
            if (mutation.isLoading) return;
            clearErrors();
            mutation.mutate(
              { session, values },
              {
                onSuccess() {
                  navigate("/userinfo/summary");
                  document.documentElement.scrollTo(0, 0);
                  toast("会員情報を更新しました", { containerId: "success" });
                },
                onError(error) {
                  if (
                    !axios.isAxiosError(error) || // 非 Axios エラー
                    !error.response || // ネットワークエラー
                    !error.response.data // 500 等
                  ) {
                    console.error(error);
                    toast("システムエラーが発生しました", {
                      containerId: "error",
                    });
                    return;
                  }
                  // バリデーションエラー
                  for (const { name, type, message } of error.response.data ||
                    []) {
                    setError(
                      name as keyof FormValues,
                      { type, message },
                      { shouldFocus: true }
                    );
                  }
                },
              }
            );
          })}
        >
          <FormGroup>
            <FormTitle title="氏名" label={false} />
            <FormData>
              {`${user.name1} ${user.name2}（${user.name_kana1} ${user.name_kana2}）`}
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="郵便番号" />
            <FormData>
              <InputText
                name="zip"
                placeholder="0000000"
                maxLength={7}
                subText={`※ハイフンなしで入力してください\n※半角数字で入力してください`}
                register={register("zip", { ...validations.required })}
                error={errors.zip}
              />
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="都道府県" label />
            <FormData>
              {prefectures.length > 0 && (
                <Select
                  name="pref"
                  options={prefectures}
                  register={register("pref", { ...validations.required })}
                  error={errors.pref}
                />
              )}
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="市区町村" label />
            <FormData>
              <InputText
                name="addr"
                placeholder=" "
                maxLength={50}
                register={register("addr", { ...validations.required })}
                error={errors.addr}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="番地" label />
            <FormData>
              <InputText
                name="houseNumber"
                placeholder=" "
                maxLength={50}
                register={register("addr_other", { ...validations.required })}
                error={errors.addr_other}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="建物名・部屋番号" required={false} />
            <FormData>
              <InputText
                name="houseName"
                placeholder=" "
                maxLength={50}
                register={register("addr_ap")}
                error={errors.addr_ap}
              />
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle
              title="電話番号"
              text="携帯電話か固定電話のどちらかが必須となります"
            />
            <FormData>
              <>
                <FormSubGroup subTitle="携帯電話番号">
                  <InputText
                    name="smartphone"
                    placeholder="08012345678"
                    maxLength={11}
                    subText={`※ハイフンなしで入力してください\n※半角数字で入力してください`}
                    register={register("phone", { ...validations.tel })}
                    error={errors.phone}
                  />
                </FormSubGroup>
                <FormSubGroup subTitle="固定電話番号">
                  <InputText
                    name="tel"
                    placeholder="08012345678"
                    maxLength={10}
                    subText={`※市外局番から入力してください\n※ハイフンなしで入力してください\n※半角数字で入力してください`}
                    register={register("tel", { ...validations.tel })}
                    error={errors.tel}
                  />
                </FormSubGroup>
              </>
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="FAX" required={false} />
            <FormData>
              <InputText
                name="fax"
                placeholder="0012345678"
                maxLength={11}
                subText={`※ハイフンなしで入力してください\n※半角数字で入力してください`}
                error={errors.fax}
                register={register("fax", { ...validations.tel })}
              />
            </FormData>
          </FormGroup>

          <ButtonBox>
            <Button type="submit">登録する</Button>
            <Button as="a" href="/userinfo/summary" color="whiteBase">
              戻る
            </Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
}
