import axios from "axios";
import { toast } from "react-toastify";
import { Session } from "../components/hooks/useSession";

// 会員証 or 解約通知書を取得し、別タブで表示
export const displayLedgerPdf = async (
  leaveoutFlg: boolean,
  carId: string,
  session: Session
) => {
  const methodName = leaveoutFlg ? "unsubscribe" : "contract";
  const openWindow = window.open("", "_blank") as Window;
  await axios
    .get(
      `${process.env.REACT_APP_REPORT_BASE_URL}/ledgers/${carId}/${methodName}`,
      {
        headers: {
          "access-token": session.accessToken,
          client: session.client,
          uid: session.uid,
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);
      openWindow.location.href = url;
    })
    .catch((err) => {
      openWindow.close();
      console.error(err);
      toast("システムエラーが発生しました", { containerId: "error" });
    });
};
