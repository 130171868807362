import { createContext, ReactNode, useMemo } from "react";

export const LoggedInFlagContext = createContext<{
  loggedInFlag: boolean;
  setLoggedIn: (flag: boolean) => void;
}>({
  loggedInFlag: false,
  setLoggedIn: () => {
    throw new Error("Need initialize method");
  },
});

type Props = {
  children: ReactNode;
  loggedInFlag: boolean;
  setLoggedIn: (flag: boolean) => void;
};

export default function LoggedInFlagProvider({
  children,
  loggedInFlag,
  setLoggedIn,
}: Props) {
  const value = useMemo(
    () => ({ loggedInFlag, setLoggedIn }),
    [loggedInFlag, setLoggedIn]
  );
  return (
    <LoggedInFlagContext.Provider value={value}>
      {children}
    </LoggedInFlagContext.Provider>
  );
}
