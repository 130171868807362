import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./Inner.module.scss";

// plugin

type InnerProps = {
  children: ReactNode;
  size?: "basic" | "wide" | "narrow";
};

export function Inner({ children, size = "basic" }: InnerProps) {
  let sizeStyle: string[] | string = "";
  if (size === "wide") {
    sizeStyle = style.isWide;
  } else if (size === "narrow") {
    sizeStyle = style.isNarrow;
  }

  return <div className={clsx(style.inner, sizeStyle)}>{children}</div>;
}
