import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import { ErrorText } from "../../components/form/ErrorText/ErrorText";
import { FormData, FormTitle } from "../../components/form/Form/Form";
import formStyle from "../../components/form/Form/Form.module.scss";
import { InputPassword } from "../../components/form/InputText/InputText";
import { useContractTypeQuery } from "../../components/hooks/useContractTypeQuery";
import { useOfferSignInMutation } from "../../components/hooks/useOfferSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

function RegisterLogin() {
  const { id } = useParams();
  const { register, handleSubmit } = useForm({
    defaultValues: { password: "" },
  });
  const mutation = useOfferSignInMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const offerContractType = useContractTypeQuery(id);
  if (!id) return null;

  const onSubmit: Parameters<typeof handleSubmit>[0] = async ({ password }) => {
    if (mutation.isLoading) return;
    setErrorMessage("");

    mutation.mutate(
      { uid: id, password },
      {
        onSuccess() {
          window.location.href = `/register/edit/${id}`;
          toast("ログインしました", { containerId: "success" });
        },
        onError(error) {
          if (
            !axios.isAxiosError(error) || // 非 Axios エラー
            !error.response?.data?.errors // 500 等
          ) {
            console.error(error);
            toast("システムエラーが発生しました", {
              containerId: "error",
            });
            return;
          }
          // 認証エラー
          setErrorMessage(error.response.data.errors[0] || "");
        },
      }
    );
  };

  return (
    <SiteLayout contractType={offerContractType}>
      <Heading1>申し込み情報登録</Heading1>
      <Text>パスワードを入力してください</Text>

      <Inner size="wide">
        <form className={formStyle.form} onSubmit={handleSubmit(onSubmit)}>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

          <FormTitle title="パスワード" label={false} />
          <FormData>
            <InputPassword
              placeholder="Password"
              name="password"
              maxLength={4}
              register={register("password")}
            />
          </FormData>

          <ButtonBox marginTop="narrow">
            <Button type="submit">
              {/* ためしに progress を使ってみる */}
              {mutation.isLoading ? <progress>loading</progress> : "ログイン"}
            </Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
}

export default RegisterLogin;
