import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import {
  FormData,
  FormGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import { InputPassword } from "../../components/form/InputText/InputText";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { Session, useSession } from "../../components/hooks/useSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { validations } from "../../components/validates/validates";

// style
import formStyle from "../../components/form/Form/Form.module.scss";

const usePasswordMutation = () => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/account_password`;
  type Variables = {
    session: Session;
    values: Record<"current_password" | "password", string>;
  };
  return useMutation<
    unknown,
    AxiosError<Record<"name" | "type" | "message", string>[]>,
    Variables
  >([url], async ({ session, values }) => {
    return axios.patch(url, values, {
      headers: {
        "access-token": session.accessToken,
        client: session.client,
        uid: session.uid,
      },
    });
  });
};

export default function UserInfoPassword() {
  useLoginCheck();
  const session = useSession();

  const {
    getValues,
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
  });
  const mutation = usePasswordMutation();
  const navigate = useNavigate();

  if (!session) return null;

  const onSubmit: Parameters<typeof handleSubmit>[0] = (values) => {
    mutation.mutate(
      { session, values },
      {
        onSuccess() {
          navigate("/userinfo/summary");
          toast("パスワードを変更しました", { containerId: "success" });
        },
        onError(e) {
          if (!axios.isAxiosError(e) || !e.response?.data) {
            toast("システムエラーが発生しました", { containerId: "error" });
            return;
          }
          for (const { name, message } of e.response.data) {
            setError(
              name as Parameters<typeof setError>[0],
              { message },
              { shouldFocus: true }
            );
          }
        },
      }
    );
  };

  const validateConfirmation = (v: string) => {
    if (!v) return;
    if (v !== getValues("password")) return "入力が一致しません";
  };

  return (
    <SiteLayout>
      <Heading1>パスワード変更</Heading1>
      <Text>
        現在登録中のパスワード、
        <br />
        新しいパスワードを入力してください。
        <br />
        確認用に再度同じパスワードを入力してください。
      </Text>
      <Inner size="wide">
        <form className={formStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormTitle title="現在のパスワード" />
            <FormData>
              <InputPassword
                name="current_password"
                autoComplete="current-password"
                placeholder="Password"
                register={register("current_password", {
                  ...validations.required,
                })}
                error={errors.current_password}
              />
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="新しいパスワード" />
            <FormData>
              <InputPassword
                name="password"
                autoComplete="new-password"
                placeholder="Password"
                subText="半角英字の大文字小文字、半角数字を含んだ8文字以上の値をご入力ください"
                register={register("password", { ...validations.required })}
                error={errors.password}
              />
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="新しいパスワード（確認用）" />
            <FormData>
              <InputPassword
                name="password_confirmation"
                autoComplete="new-password"
                placeholder="Password"
                register={register("password_confirmation", {
                  ...validations.required,
                  validate: validateConfirmation,
                })}
                error={errors.password_confirmation}
              />
            </FormData>
          </FormGroup>
          <ButtonBox>
            <Button type="submit">設定する</Button>
            <Button as="a" href="/userinfo/summary" color="whiteBase">
              戻る
            </Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
}
