// common
import { Button, ButtonBox } from "../../common/Button/Button";
import { Session } from "../../hooks/useSession";
import { displayLedgerPdf } from "../../../utils/displayLedgerPdf";

type MemberId = {
  carId: string;
  name: string;
  leaveoutFlg: boolean;
  session: Session;
};

export function MemberIdLink({ carId, name, leaveoutFlg, session }: MemberId) {
  const displayLedger = async () => {
    await displayLedgerPdf(leaveoutFlg, carId, session);
  };

  return (
    <ButtonBox marginTop="tiny">
      <Button as="a" onClick={() => displayLedger()}>
        {name} の会員証を表示する
      </Button>
    </ButtonBox>
  );
}
