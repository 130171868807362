import { createContext, ReactNode, useMemo } from "react";

export const SelectedUserCarIdContext = createContext<{
  // 変数定義
  selectedUserCarId: string;
  setSelectedUserCarId: (car_id: string) => void;
}>({
  // 初期値
  selectedUserCarId: "1",
  setSelectedUserCarId: () => {
    throw new Error("Need initialize method");
  },
});

type Props = {
  children: ReactNode;
  selectedUserCarId: string;
  setSelectedUserCarId: (car_id: string) => void;
};

export default function SelectedUserCarIdProvider({
  children,
  selectedUserCarId,
  setSelectedUserCarId,
}: Props) {
  const value = useMemo(
    () => ({ selectedUserCarId, setSelectedUserCarId }),
    [selectedUserCarId, setSelectedUserCarId]
  );
  return (
    <SelectedUserCarIdContext.Provider value={value}>
      {children}
    </SelectedUserCarIdContext.Provider>
  );
}
