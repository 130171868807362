/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1, Heading2 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { useSessionTimedOut } from "../../components/hooks/useSession";

export default function RegisterComplete() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const uid = searchParams.get("uid");
  const [loading, setLoading] = useState(true);
  const gmoStatus = {
    success: "success",
    error: "error",
  };

  const closeWindow = () => {
    window.open("about:blank", "_self")?.close();
  };

  // クエリでページの出しわけを行う
  useEffect(() => {
    if (uid && (status === gmoStatus.success || status === gmoStatus.error)) {
      setLoading(false);
    }
  }, [gmoStatus.error, gmoStatus.success, navigate, status, uid]);

  // セッションがないor一致しない場合はloginページへリダイレクト
  useSessionTimedOut(uid || null);

  return (
    <SiteLayout>
      {!loading &&
        ((status === gmoStatus.success && (
          <>
            <Heading1>
              申し込み情報入力
              <br className="isOnlySp" />
              完了
            </Heading1>
            <Text>お客様情報のご入力が完了しました</Text>

            <ButtonBox marginTop="narrow">
              <Button type="button" onClick={() => closeWindow()}>
                閉じる
              </Button>
            </ButtonBox>
          </>
        )) ||
          (status === gmoStatus.error && (
            <>
              <Heading2>
                クレジットカード登録が
                <br />
                完了しておりません
              </Heading2>
              <Text>
                お手数ですが、
                <br />
                以下のボタンより申し込み画面へお戻り頂き、
                <br />
                入力内容のご確認の上、再度お試し下さい。
              </Text>
              <ButtonBox marginTop="narrow">
                <Button as="a" href={`/register/edit/${uid}`}>
                  申し込み画面へ戻る
                </Button>
              </ButtonBox>
            </>
          )))}
    </SiteLayout>
  );
}
