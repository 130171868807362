import { useState } from "react";

// style
import { SlideDown } from "react-slidedown";
import style from "./MaintenanceHistoryList.module.scss";

// common
import { Button } from "../../common/Button/Button";

// mypage
import { MaintenanceImage } from "../MaintenanceImage/MaintenanceImage";

// plugin
import "react-slidedown/lib/slidedown.css";
import { MaintenanceHistory } from "../../types/maintenanceHistory";

export function MaintenanceHistoryItem({
  date,
  maintenanceShopName,
  status,
  detail,
  remarks,
  images,
}: MaintenanceHistory) {
  // 詳細トグル開閉
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const handleToggle = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  // 詳細トグル閉じる
  const handleClose = () => {
    setIsToggleOpen(false);
  };

  return (
    <>
      <ul className={style.about}>
        <li className={style.mDate}>
          <span className={style.heading}>日時</span>
          {date}
        </li>
        <li className={style.shop}>
          <span className={style.heading}>整備工場名</span>
          {maintenanceShopName}
        </li>
        <li className={style.status}>
          <span className={style.heading}>メンテナンス状況</span>
          {status}
        </li>
        <li className={style.button}>
          <Button
            size="small"
            color="whiteGray"
            toggleIcon
            toggleFlg={isToggleOpen}
            onClick={handleToggle}
          >
            詳細
          </Button>
        </li>
      </ul>

      <SlideDown className={style.down}>
        {isToggleOpen && (
          <div className={style.detail}>
            <ul className={style.detailList}>
              <li className={style.detailItem}>
                <span className={style.heading}>メンテナンス内容</span>
                {detail}
              </li>
              <li className={style.detailItem}>
                <span className={style.heading}>備考</span>
                {remarks}
              </li>
              <li className={style.detailItem}>
                <span className={style.heading}>写真</span>
                <ul className={style.imageList}>
                  {images.map((image, index) => {
                    return (
                      // 主キーなどが無いためkeyにindex使用
                      // eslint-disable-next-line
                      <li className={style.imageItem} key={index}>
                        <MaintenanceImage src={image} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
            <Button size="small" color="whiteGray" onClick={handleClose}>
              詳細を閉じる
            </Button>
          </div>
        )}
      </SlideDown>
    </>
  );
}

type Props = {
  maintenanceHistories: MaintenanceHistory[];
};

export function MaintenanceHistoryList({ maintenanceHistories }: Props) {
  return (
    <>
      <ul className={style.title}>
        <li className={style.mDate}>日時</li>
        <li className={style.shop}>整備工場名</li>
        <li className={style.status}>メンテナンス状況</li>
        <li className={style.button}>詳細</li>
      </ul>

      <ul className={style.list}>
        {maintenanceHistories.map((mtResult, index) => {
          return (
            // 主キーなどが無いためkeyにindex使用
            // eslint-disable-next-line
            <li key={index} className={style.item}>
              <MaintenanceHistoryItem
                date={mtResult.date}
                maintenanceShopName={mtResult.maintenanceShopName}
                status={mtResult.status}
                detail={mtResult.detail}
                remarks={mtResult.remarks}
                images={mtResult.images}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}
