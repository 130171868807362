import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./InnerTextArea.module.scss";

// plugin

type Props = {
  children: ReactNode;
};

export function InnerTextArea({ children }: Props) {
  return <div className={clsx(style.inner_text)}>{children}</div>;
}
