import { useState, useContext, useMemo } from "react";

// plugin
import clsx from "clsx";
import { SlideDown } from "react-slidedown";
import { Link } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";

// style
import style from "./Header.module.scss";

// providers
import { LoggedInFlagContext } from "../../providers/LoggedInFlagProvider";
import { useSession, useSetSession } from "../../hooks/useSession";
import { useProfileQuery } from "../../hooks/useProfileQuery";

// Logo
import { Logo } from "./Logo";
import { useResetUserCars } from "../../hooks/useUserCars";

type Props = {
  refleshInquiry?: () => void;
  contractType?: string;
};

export function Header({ refleshInquiry, contractType }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { loggedInFlag } = useContext(LoggedInFlagContext);
  const session = useSession();
  const setSession = useSetSession();
  const { data } = useProfileQuery(session);
  const userName = useMemo(
    () => (data ? `${data.name1} ${data.name2}` : ""),
    [data]
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // // ログアウト時に実行
  useResetUserCars();

  return (
    <header className={style.header}>
      <div className={style.inner}>
        <Logo contractType={contractType} />
        {loggedInFlag && userName ? (
          <button
            type="button"
            onClick={handleToggle}
            className={clsx(style.name, isOpen && style.isOpen)}
          >
            <span className="isOnlyPc">{userName} 様</span>
            <span
              className={clsx(
                style.isOnlySp,
                style.line,
                isOpen && style.isOpen
              )}
            />
          </button>
        ) : (
          ""
        )}
      </div>

      <SlideDown className={style.panel}>
        {isOpen && (
          <div className={clsx(style.menu, style.isOpen)}>
            <ul>
              <li className={clsx(style.item)}>
                <Link to="/" className={clsx(style.link, style.home)}>
                  マイページトップ
                </Link>
              </li>
              <li className={style.item}>
                <Link
                  to="/userinfo/summary"
                  className={clsx(style.link, style.user)}
                >
                  会員情報
                </Link>
              </li>
              <li className={style.item}>
                <Link
                  to="/inquiry/edit"
                  className={clsx(style.link, style.inquiry)}
                  onClick={() => {
                    if (refleshInquiry) refleshInquiry();
                  }}
                >
                  お問い合わせ
                </Link>
              </li>
              <li className={style.item}>
                <Link
                  to="/login"
                  className={clsx(style.link, style.logout)}
                  onClick={() => setSession(null)}
                >
                  ログアウト
                </Link>
              </li>
            </ul>
          </div>
        )}
      </SlideDown>
    </header>
  );
}
