import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { Session } from "./useSession";

export type User = {
  name1: string;
  name2: string;
  name_kana1: string;
  name_kana2: string;
  phone: string;
  tel: string;
  fax: string;
  zip: string;
  pref: string;
  addr: string;
  addr_other: string;
  addr_ap: string;
  email: string;
};

export const useProfileQuery = (
  session: Session | null,
  options?: Omit<UseQueryOptions<User, AxiosError>, "queryKey" | "queryFn">
) => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/profile`;
  return useQuery<User, AxiosError>(
    [url],
    async () => {
      const { data } = await axios.get<User>(url, {
        headers: {
          "access-token": session?.accessToken || "",
          client: session?.client || "",
          uid: session?.uid || "",
        },
      });
      return data;
    },
    { enabled: session != null, ...options }
  );
};
