// style
import style from "./NextMaintenanceScreen.module.scss";

type Props = {
  date: string;
  shopName: string;
  shopAddress: string;
  shopTel: string;
  detail: string;
  status?: string;
  linkText?: string;
  url?: string;
};

export function NextMaintenanceScreen({
  date,
  shopName,
  shopAddress,
  shopTel,
  detail,
  status = "予定",
  linkText,
  url,
}: Props) {
  return (
    <div className={style.wrapper}>
      <dl className={style.date}>
        {(status === "予定" && (
          <>
            <span className={style.number}>基準日：{date}</span>
            <ul>
              <li className={style.attention}>
                ※基準日の1ヵ月前～3ヵ月後の間で実施してください。
              </li>
              <li className={style.attention}>
                ※ご予約は下記「予約候補日を選択する」よりお願い致します。
              </li>
            </ul>
          </>
        )) ||
          (["予約中", "作業中"].includes(status) && (
            <>
              <span className={style.number}>{date}</span>
              <ul>
                <li className={style.attention2}>ご予約が確定いたしました</li>
              </ul>
            </>
          ))}
      </dl>

      <dl className={style.item}>
        <dt className={style.title}>整備工場名</dt>
        <dd>
          {shopName}
          <p className={style.shop}>{shopAddress}</p>
          <p className={style.shop}>TEL：{shopTel}</p>
          {linkText && (
            <p className={style.link}>
              <a href={url} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            </p>
          )}
        </dd>
      </dl>
      <dl className={style.item}>
        <dt className={style.title}>メンテナンス内容</dt>
        <dd>{detail}</dd>
      </dl>
    </div>
  );
}
