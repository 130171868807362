export const validations = {
  required: { required: { value: true, message: "値が入力されていません" } },
  notChecked: {
    required: { value: true, message: "チェックを入れてください" },
  },
  kana: {
    pattern: {
      value: /^[ァ-ヶー]*$/,
      message: "全角カタカナで名前を入力してください",
    },
  },
  halfNumber: {
    pattern: {
      value: /^[0-9]*$/,
      message: "半角数字で入力してください",
    },
  },
  phone: {
    pattern: {
      value: /^[0-9]*$/,
      message: "携帯電話番号は10桁もしくは11桁の半角数字でご入力ください",
    },
  },
  tel: {
    pattern: {
      value: /^[0-9]*$/,
      message: "固定電話番号は10桁もしくは11桁の半角数字でご入力ください",
    },
  },
  fax: {
    pattern: {
      value: /^[0-9]*$/,
      message: "FAXは10桁もしくは11桁の半角数字でご入力ください",
    },
  },
};
