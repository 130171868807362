// common
import { Heading1 } from "../../common/Heading/Heading";
import { Text } from "../../common/Text/Text";
import { Button, ButtonBox } from "../../common/Button/Button";

export function PasswordComplete() {
  return (
    <>
      <Heading1>
        パスワード設定
        <br className="isOnlySp" />
        完了
      </Heading1>
      <Text>パスワードの再設定が完了しました</Text>

      <ButtonBox marginTop="narrow">
        <Button as="a" href="/login">
          ログイン画面へ
        </Button>
      </ButtonBox>
    </>
  );
}
