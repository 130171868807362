import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from "react";

// style
import clsx from "clsx";
import style from "./InputText.module.scss";

// plugin

// テキストボックス
type InputTextProps = {
  type?: "text" | "email" | "tel";
  name: string;
  placeholder?: string;
  defaultValue?: string;
  maxLength?: number;
  subTitle?: string;
  subText?: string;

  // エラー
  error?: {
    type?: string; // 使わない
    message?: string; // これを表示
  };
  errors?: object;
  register?: object;
  errorsMessage?: string;
  // <input> の属性 (maxlength, required 等)
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function InputText({
  type = "text",
  name,
  placeholder,
  defaultValue,
  maxLength,
  subTitle,
  subText,
  error,
  errors,
  register,
  errorsMessage,
  ...inputProps
}: InputTextProps) {
  return (
    <>
      {subTitle && <p className={style.subTitle}>{subTitle}</p>}
      {(error || errors) && (error?.message || errorsMessage) && (
        <p className={style.errorText}>{error?.message || errorsMessage}</p>
      )}

      <div className={style.wrapper}>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={clsx(style.input, (error || errors) && style.isError)}
          {...inputProps}
          {...register}
          maxLength={maxLength}
        />
      </div>

      {subText && <p className={style.subText}>{subText}</p>}
    </>
  );
}

type InputPasswordProps = {
  name: string;
  placeholder: string;
  maxLength?: number;
  subText?: string;

  // エラー
  error?: {
    type?: string; // 使わない
    message?: string; // これを表示
  };
  errors?: object;
  register?: object;
  errorsMessage?: string;
  // <input> の属性 (maxlength, required 等)
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function InputPassword({
  name,
  placeholder,
  maxLength,
  subText,
  error,
  errors,
  register,
  errorsMessage,
  ...inputProps
}: InputPasswordProps) {
  // パスワード表示・非表示
  const [isHide, setIsHide] = useState(true);

  const [type, setType] = useState("password");

  const handlePassword = () => {
    setIsHide(!isHide);

    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <>
      {(error || errors) && (error?.message || errorsMessage) && (
        <p className={style.errorText}>{error?.message || errorsMessage}</p>
      )}
      <div className={style.wrapper}>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          className={clsx(style.input, (error || errors) && style.isError)}
          {...inputProps}
          {...register}
        />
        <span
          onClick={handlePassword}
          role="presentation"
          className={clsx(style.eye, isHide === true && style.isHide)}
        />
      </div>

      {subText && <p className={style.subText}>{subText}</p>}
    </>
  );
}
