import { MouseEventHandler, useCallback, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import style from "./Message.module.scss";

export type ToastType = "success" | "error";

type Props = {
  text: string;
  type: ToastType;
  onClose?: () => void;
};

function CloseButton({
  closeToast,
}: {
  closeToast: MouseEventHandler<HTMLElement>;
}) {
  return (
    // TODO: i から button に変えたため見た目が崩れているかもしれない
    <button className={style.close} onClick={closeToast} type="button">
      delete
    </button>
  );
}

export function Message({ text, type, onClose }: Props) {
  // ローカル環境のStrictModeでuseEffectが2重で走ることの対策
  const running = useRef(false);
  const notify = useCallback(() => {
    // toastのonCloseがうまく動作しないので、toastがremoveされた時に発火させる
    toast.onChange((v) => {
      if (v.status === "removed") {
        if (onClose) {
          onClose();
        }
        running.current = false;
      }
    });

    switch (type) {
      case "error":
        toast.error(text);
        break;
      case "success":
      default:
        toast.success(text);
    }
  }, [text, type, onClose]);

  useEffect(() => {
    // 2重で呼び出されないように対応
    if (!running.current) {
      running.current = true;
      notify();
    }
  }, []);

  return (
    <div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        className={style.box}
        toastClassName={type === "error" ? style.toast_error : style.toast}
        bodyClassName={style.body}
        closeButton={CloseButton}
      />
    </div>
  );
}

export const Toast = ({ id, type }: { id?: string; type: ToastType }) => {
  return (
    <ToastContainer
      enableMultiContainer
      containerId={id}
      autoClose={3000}
      hideProgressBar
      className={style.box}
      toastClassName={type === "error" ? style.toast_error : style.toast}
      bodyClassName={style.body}
      closeButton={CloseButton}
    />
  );
};
