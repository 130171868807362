import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { subMonths, addMonths } from "date-fns";

import { Control, Controller, Path } from "react-hook-form";

// style
import "react-datepicker/dist/react-datepicker.min.css";
import clsx from "clsx";
import style from "./InputDate.module.scss";

// plugin

// テキストボックス
type InputDateProps<T> = {
  name: Path<T>;
  placeholder?: string;
  startDate: string;
  register?: object;
  control: Control<T>;

  // エラー
  error?: {
    type?: string; // 使わない
    message?: string; // これを表示
  };
};

export const InputDate = <T,>({
  name,
  placeholder,
  error,
  startDate,
  control,
}: InputDateProps<T>) => {
  registerLocale("ja", ja);

  return (
    <>
      {error && <p className={style.errorText}>{error.message}</p>}

      <div className={style.wrapper}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value as Date}
              onChange={onChange}
              className={clsx(style.input, error && style.isError)}
              calendarClassName={style.calendar}
              popperModifiers={[
                {
                  name: "arrow",
                  options: {
                    padding: ({ popper, reference }) => ({
                      right: Math.min(popper.width, reference.width) - 40,
                    }),
                  },
                },
              ]}
              placeholderText={placeholder}
              name={name}
              locale="ja"
              dateFormat="yyyy/MM/dd"
              minDate={subMonths(new Date(startDate), 1)}
              maxDate={addMonths(new Date(startDate), 3)}
              openToDate={new Date(startDate)}
            />
          )}
        />
      </div>
    </>
  );
};
