import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./ErrorText.module.scss";

// plugin

type Props = {
  children: ReactNode;
  position?: "top" | "bottom";
};

export function ErrorText({ children, position = "top" }: Props) {
  return (
    <div
      className={clsx(style.wrapper, position === "bottom" && style.isBottom)}
    >
      <p className={style.text}>{children}</p>
    </div>
  );
}
