// node_modules
import { useMemo, useRef, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// style
import { SlideDown } from "react-slidedown";

import style from "../../components/page/MyPage/MyPage.module.scss";

// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import {
  Heading1,
  Heading2,
  Heading3,
} from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Inner } from "../../components/common/Inner/Inner";
import { Button, ButtonBox } from "../../components/common/Button/Button";

// form
import { Select } from "../../components/form/Select/Select";

// mypage
import { NextMaintenanceScreen } from "../../components/mypage/NextMaintenanceScreen";
import { OaaMaintenances } from "../../components/mypage/OaaMaintenances";
import { MaintenanceHistoryList } from "../../components/mypage/MaintenanceHistoryList";
import {
  MaintenanceSchedule,
  MaintenanceScheduleItem,
} from "../../components/mypage/MaintenanceSchedule/MaintenanceSchedule";

// plugin
import "react-slidedown/lib/slidedown.css";

// カスタムhooks
import { useUserCars } from "../../components/hooks/useUserCars";
import { useSettlement } from "../../components/hooks/useSettlement";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { useUserMaintenance } from "../../components/hooks/useUserMaintenance";
import { ServerError } from "../error/500";
import { useSession } from "../../components/hooks/useSession";

// utils
import { displayLedgerPdf } from "../../utils/displayLedgerPdf";

// Provider
import { SelectedUserCarIdContext } from "../../components/providers/SelectedUserCarIdProvider";
import {
  transitGmoSiteByCarId,
  useNoticeGmoResult,
} from "../../components/hooks/useGmoQuery";

function MypageTop() {
  const session = useSession();
  // ログインチェック
  useLoginCheck();

  const navigate = useNavigate();

  useNoticeGmoResult();

  // 車両情報
  const {
    name,
    carId,
    userCars,
    isLeaveout,
    handleUserCars,
    query: userCarQuery,
  } = useUserCars();

  // メンテナンス情報
  const {
    maintenanceHistories,
    nextMaintenance,
    afterNextMaintenances,
    query: maintenanceQuery,
  } = useUserMaintenance(carId);

  const { settlement } = useSettlement(carId);

  // 解約通知書情報
  const dt = useRef(new Date());
  const report = useMemo(
    () => ({
      year: dt.current.getFullYear() + 1,
      month: dt.current.getMonth() + 1,
      day: dt.current.getDate(),
    }),
    []
  );

  const displayLedger = async () => {
    if (session) {
      displayLedgerPdf(true, carId, session);
    }
  };

  // アコーディオンメニュー開閉用
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // 次々回以降のメンテナンス閉じる
  const handleClose = () => {
    setIsOpen(false);
  };

  // コンテキストに選択されたcar_idを保存
  const { setSelectedUserCarId } = useContext(SelectedUserCarIdContext);
  useEffect(() => setSelectedUserCarId(carId), [carId, setSelectedUserCarId]);

  if (
    userCarQuery.error?.response?.status === 401 ||
    maintenanceQuery.error?.response?.status === 401
  ) {
    // 認証エラー
    navigate("/login");
    return null;
  }

  if (
    userCarQuery.error?.response?.status === 500 ||
    maintenanceQuery.error?.response?.status === 500
  ) {
    // ServerError
    return <ServerError />;
  }

  // TODO loading表示

  return (
    <SiteLayout size="wide">
      <Heading1>マイページ</Heading1>
      <Text>{name} 様</Text>
      <Heading2>メンテナンス一覧</Heading2>
      <div className={style.select}>
        <Select
          onChange={handleUserCars}
          name="userCars"
          options={userCars ?? []}
        />
      </div>
      {isLeaveout ? (
        <>
          <div className={style.text}>
            <p>
              <span>{userCars.find((uc) => uc.value === carId)?.text}</span>
              は解約済みの車両です。
              <br />
              以下のボタンより解約通知書の確認と保存が可能です。
            </p>
          </div>
          {report && (
            <>
              <ButtonBox>
                <Button as="a" onClick={() => displayLedger()}>
                  解約通知書を表示する
                </Button>
              </ButtonBox>
              <div className={style.subText}>
                <p>
                  確認期限：{report.year}年{report.month}月{report.day}
                  日まで（本日より1年間）
                </p>
                <p>解約通知書の保存はご利用のブラウザから行なってください</p>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <Inner size="narrow">
            <>
              {nextMaintenance && (
                <>
                  <Heading3>
                    次回メンテナンス
                    {nextMaintenance.status === "予定" && "（予定）"}
                    {["予約中", "作業中"].includes(nextMaintenance.status) &&
                      "（確定）"}
                  </Heading3>
                  <NextMaintenanceScreen
                    date={nextMaintenance.date}
                    shopName={nextMaintenance.maintenanceShop.name}
                    shopAddress={nextMaintenance.maintenanceShop.address}
                    shopTel={nextMaintenance.maintenanceShop.tel}
                    detail={nextMaintenance.detail}
                    status={nextMaintenance.status}
                    linkText="整備工場一覧へ"
                    url="https://www.fixmanclub.com/garagelist/"
                  />
                </>
              )}

              {nextMaintenance && nextMaintenance.status === "予定" && (
                <div className={style.candidate}>
                  {nextMaintenance.schedule_datetime1 &&
                    nextMaintenance.schedule_datetime2 &&
                    nextMaintenance.schedule_datetime3 && (
                      <>
                        <Heading3>予約候補</Heading3>
                        <p className={style.candidateText}>
                          以下の日程で予約候補日を登録しています。予約の確定までしばらくお待ちください。
                        </p>
                        <MaintenanceSchedule>
                          <MaintenanceScheduleItem
                            title="第一候補"
                            data={nextMaintenance.schedule_datetime1}
                            lineStyle="short"
                          />
                          <MaintenanceScheduleItem
                            title="第二候補"
                            data={nextMaintenance.schedule_datetime2}
                            lineStyle="short"
                          />
                          <MaintenanceScheduleItem
                            title="第三候補"
                            data={nextMaintenance.schedule_datetime3}
                            lineStyle="short"
                          />
                        </MaintenanceSchedule>
                        {nextMaintenance.status === "予定" && (
                          <ButtonBox marginTop="tiny">
                            <Button
                              as="a"
                              href={`/maintenance_schedule/edit?carId=${carId}`}
                            >
                              予約候補日を変更する
                            </Button>
                          </ButtonBox>
                        )}
                      </>
                    )}
                  {!nextMaintenance.schedule_datetime1 &&
                    !nextMaintenance.schedule_datetime2 &&
                    !nextMaintenance.schedule_datetime3 && (
                      <ButtonBox marginTop="tiny">
                        <Button
                          as="a"
                          href={`/maintenance_schedule/edit?carId=${carId}`}
                        >
                          予約候補日を指定する
                        </Button>
                      </ButtonBox>
                    )}
                </div>
              )}

              {afterNextMaintenances.length > 0 && (
                <>
                  <div className={style.button}>
                    <Button
                      size="small"
                      color="whiteGray"
                      toggleIcon
                      onClick={handleToggle}
                      toggleFlg={isOpen}
                    >
                      次々回以降のメンテナンス
                    </Button>
                  </div>

                  <SlideDown className={style.list}>
                    {isOpen && (
                      <>
                        <Heading3>次々回以降のメンテナンス</Heading3>
                        <OaaMaintenances
                          oaaMaintenances={afterNextMaintenances}
                        />
                        <div className={style.button}>
                          <Button
                            size="small"
                            color="whiteGray"
                            onClick={handleClose}
                          >
                            次々回以降のメンテナンスを閉じる
                          </Button>
                        </div>
                      </>
                    )}
                  </SlideDown>
                </>
              )}
            </>
          </Inner>
          {settlement && (
            <Inner size="narrow">
              <div className={style.flex}>
                <Heading3 styles={{ marginBottom: 0 }}>次回支払情報</Heading3>
                <Button
                  as="a"
                  onClick={() => transitGmoSiteByCarId(carId)}
                  size="small"
                  color="whiteGray"
                >
                  カード情報を変更する
                </Button>
              </div>
              <MaintenanceSchedule
                date={settlement.pay_date?.replace(/-/g, "/")}
              >
                <MaintenanceScheduleItem
                  title="支払い金額"
                  data={`${settlement.price}円`}
                  lineStyle="short"
                />
                <MaintenanceScheduleItem
                  title="支払回数"
                  data={`${settlement.billed_time}回目 / ${settlement.total_billing_time}回目`}
                  lineStyle="short"
                />
              </MaintenanceSchedule>
            </Inner>
          )}
          <Inner size="narrow">
            <Heading3>メンテナンス履歴</Heading3>
            <div className={style.log}>
              <MaintenanceHistoryList
                maintenanceHistories={maintenanceHistories}
              />
            </div>
          </Inner>
        </>
      )}
    </SiteLayout>
  );
}

export default MypageTop;
