import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const url = `${process.env.REACT_APP_API_V2_BASE_URL}/offer/contract_type`;

type ApiResponse = {
  contract_type: string;
  error: string;
};

type FetchApiType = () => Promise<ApiResponse | undefined>;

// uidでofferテーブルを参照し、contractTypeを返却する
// useOfferQueryと異なり、sessionは使用しない
export const contractQuery = async (uid: string) => {
  const options: AxiosRequestConfig = {
    url,
    method: "GET",
    params: { uid },
  };

  const FetchApi: FetchApiType = async () => {
    try {
      const res: AxiosResponse<ApiResponse> = await axios(options);
      return res.data;
    } catch (error) {
      toast("通信エラーが発生しました", { containerId: "error" });
      console.error(error);
    }
  };

  const contractTypeRes: ApiResponse | undefined = await FetchApi();
  return { contractTypeRes };
};

export const useContractTypeQuery = (id: string | undefined) => {
  const [contractType, setContractType] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (id !== undefined) {
      (async () => {
        const { contractTypeRes } = await contractQuery(id);
        if (!contractTypeRes) return null;
        if (contractTypeRes.error) {
          toast("通信エラーが発生しました", { containerId: "error" });
          console.error(contractTypeRes.error);
          return null;
        }
        setContractType(contractTypeRes.contract_type);
      })();
    }
  }, [id]);
  return contractType;
};
