// style
import { OaaMaintenance } from "../../types/oaaMaintenance";
import style from "./OaaMaintenances.module.scss";

type Props = {
  oaaMaintenances: OaaMaintenance[];
};

export function OaaMaintenances({ oaaMaintenances }: Props) {
  return (
    <>
      <div className={style.title}>
        <div className={style.date}>日時</div>
        <div className={style.description}>メンテナンス内容</div>
      </div>

      <ul className={style.list}>
        {oaaMaintenances.map((maintenance, index) => {
          return (
            // 主キーなどが無いためkeyにindex使用
            // eslint-disable-next-line
            <li className={style.item} key={index}>
              <p className={style.date}>{maintenance.date}</p>
              <p className={style.description}>{maintenance.detail}</p>
            </li>
          );
        })}
      </ul>
    </>
  );
}
