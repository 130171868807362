import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./Text.module.scss";

// plugin

type TextProps = {
  children: ReactNode;
  marginBottom?: "normal" | "narrow";
};

export function Text({ children, marginBottom = "normal" }: TextProps) {
  return (
    <div
      className={clsx(style.text, marginBottom === "narrow" && style.isNarrow)}
    >
      {children}
    </div>
  );
}
