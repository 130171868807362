import { DetailedHTMLProps, InputHTMLAttributes } from "react";

// style
import style from "./InputRadio.module.scss";

type MyTextInputProps = {
  // このコンポーネント用
  label: string;
  defaultChecked?: boolean;
  radioLists?: [];

  // react-hook-form で使用
  name?: string;
  // register?: (input: HTMLInputElement) => void;
  register?: object;

  key?: string;
  // Detailed～ は <input> の属性らしい (maxlength, required 等)
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function InputRadio({
  label,
  defaultChecked = false,
  name,
  value,
  register,
  ...inputProps
}: MyTextInputProps) {
  return (
    <div className={style.radio}>
      <label>
        <input
          type="radio"
          name={name}
          value={value}
          {...register}
          defaultChecked={defaultChecked}
          {...inputProps}
        />
        <span className={style.text}>{label}</span>
      </label>
    </div>
  );
}

type InputRadioListProps = {
  radioLists: {
    label: string;
    defaultChecked?: boolean;
    name?: string;
    value?: string;
    key?: string;
  }[];
  register?: object;
  error?: {
    type?: string; // 使わない
    message?: string; // これを表示
  };
};

export function InputRadioList({
  radioLists,
  register,
  error,
}: InputRadioListProps): JSX.Element {
  return (
    <>
      {error && <p className={style.errorText}>{error?.message}</p>}
      <ul className={style.list}>
        {radioLists.map((radioList) => {
          return (
            <li key={radioList.key} className={style.item}>
              <InputRadio
                label={radioList.label}
                defaultChecked={radioList.defaultChecked}
                name={radioList.name}
                value={radioList.value}
                key={radioList.key}
                register={register}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}
