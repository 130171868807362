import { ReactNode, DetailedHTMLProps, InputHTMLAttributes } from "react";

// style
import style from "./CheckBox.module.scss";

type Props = {
  // このコンポーネント用
  label: string | ReactNode;
  defaultChecked?: boolean;
  // children: ReactNode;

  // react-hook-form で使用
  name?: string;
  register?: object;
  error?: {
    message?: string;
  };
  key?: string;
  // Detailed～ は <input> の属性らしい (maxlength, required 等)
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function CheckBox({
  label,
  defaultChecked = false,
  name,
  value,
  register,
  error,
  ...inputProps
}: Props) {
  return (
    <>
      {error && error?.message && (
        <p className={style.errorText}>{error?.message}</p>
      )}
      <div className={style.checkbox}>
        <label>
          <input
            type="checkbox"
            name={name}
            value={value}
            {...register}
            defaultChecked={defaultChecked}
            {...inputProps}
          />
          <span className={style.text}>{label}</span>
        </label>
      </div>
    </>
  );
}
