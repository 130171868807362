import { MouseEventHandler, useState } from "react";

// style
import style from "./MaintenanceImage.module.scss";

// common
import { Modal } from "../../common/Modal/Modal";

// hooks
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

type MaintenanceImageProps = {
  src: string;
};

export function MaintenanceImage({ src }: MaintenanceImageProps) {
  // スクロール禁止
  const [{ handleLockBodyScroll }] = useLockBodyScroll();

  // 画像拡大モーダル
  const [isOpen, setIsOpen] = useState(false);

  const handleModal: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    handleLockBodyScroll();
  };

  return (
    <>
      <a href={src} onClick={handleModal}>
        <figure className={style.photo}>
          <img src={src} alt="" />
        </figure>
      </a>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <img src={src} className={style.modalPhoto} alt="" />
      </Modal>
    </>
  );
}
