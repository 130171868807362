import { ElementType, ReactNode } from "react";

// style
import style from "./Heading.module.scss";

type Heading1Props = {
  as?: ElementType;
  children: ReactNode;
};

export function Heading1({ as: Tag = "h1", children }: Heading1Props) {
  return <Tag className={style.heading1}>{children}</Tag>;
}

type Heading2Props = {
  as?: ElementType;
  children: ReactNode;
};

export function Heading2({ as: Tag = "h2", children }: Heading2Props) {
  return <Tag className={style.heading2}>{children}</Tag>;
}

type Heading3Props = {
  as?: ElementType;
  styles?: React.CSSProperties;
  children: ReactNode;
};

export function Heading3({
  as: Tag = "h3",
  styles,
  children,
}: Heading3Props): JSX.Element {
  return (
    <Tag className={style.heading3} style={styles}>
      {children}
    </Tag>
  );
}
