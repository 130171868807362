// style
import { ReactNode } from "react";
import clsx from "clsx";
import style from "./MaintenanceSchedule.module.scss";

// plugin

type MaintenanceScheduleItemProps = {
  title: string;
  data: string;
  address?: string;
  tel?: string;
  linkText?: string;
  url?: string;
  lineStyle?: "normal" | "short";
};

export function MaintenanceScheduleItem({
  title,
  data,
  address,
  tel,
  linkText,
  url,
  lineStyle = "normal",
}: MaintenanceScheduleItemProps) {
  return (
    <dl className={clsx(style.item, lineStyle === "short" && style.isShort)}>
      <dt className={style.title}>{title}</dt>
      <dd>
        {data}
        {address && <p className={style.shop}>{address}</p>}
        {tel && <p className={style.shop}>TEL：{tel}</p>}
        {linkText && (
          <p className={style.link}>
            <a href={url} target="_blank" rel="noreferrer">
              {linkText}
            </a>
          </p>
        )}
      </dd>
    </dl>
  );
}

type MaintenanceScheduleProps = {
  direction?: "vertical" | "horizontal";
  date?: string;
  time?: string;
  dateTitle?: string;
  decisionFlag?: boolean;
  attentionList?: {
    item: string;
  }[];
  children: ReactNode;
};

export function MaintenanceSchedule({
  direction = "horizontal",
  date,
  time,
  dateTitle,
  decisionFlag = false,
  attentionList,
  children,
}: MaintenanceScheduleProps) {
  return (
    <div
      className={clsx(
        style.wrapper,
        direction === "vertical" && style.isVertical
      )}
    >
      {date && (
        <div className={style.date}>
          {dateTitle &&
            (decisionFlag ? (
              <>
                <p className={style.number}>
                  {date} {time}
                </p>
                <p className={style.reserved}>ご予約が確定いたしました</p>
              </>
            ) : (
              <p className={style.number}>
                {dateTitle}：{date}
              </p>
            ))}
          {!dateTitle && <p className={style.number}>{date}</p>}

          {attentionList && (
            <ul>
              {attentionList.map((attentionLists) => {
                return (
                  <li className={style.attention} key={attentionLists.item}>
                    {attentionLists.item}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {children}
    </div>
  );
}
