// node_modules
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export const usePasswordResetTokenCheck = (token: string) => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/auth/password/edit?reset_password_token=${token}`;
  const fetcher = async () => {
    const res = await axios.get(url, {});
    return res.data;
  };
  return useQuery<unknown, AxiosError>([url], fetcher, {
    enabled: token !== "",
    staleTime: 60 * 60 * 1000,
  });
};
