// style

// plugin
import clsx from "clsx";
import React, { ChangeEventHandler } from "react";
import style from "./Select.module.scss";

type Props = {
  name: string;
  defaultValue?: string;
  options: {
    text?: string;
    value?: string;
    defaultHidden?: boolean;
    index?: number;
    selected?: boolean;
  }[];

  error?: {
    message?: string;
  };
  errors?: object;
  register?: object;
  errorsMessage?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export function Select({
  name,
  options,
  error,
  errors,
  register,
  errorsMessage,
  defaultValue,
  onChange,
}: Props) {
  return (
    <>
      {(error || errors) && (error?.message || errorsMessage) && (
        <p className={style.errorText}>{error?.message || errorsMessage}</p>
      )}
      <div className={style.wrapper}>
        <select
          name={name}
          className={clsx(style.select, (error || errors) && style.isError)}
          defaultValue={defaultValue}
          {...register} // onChangeを動作させるためにonChangeより先にregisterする
          onChange={onChange}
        >
          {options.map((option) => {
            return (
              <option
                value={option.value}
                key={option.index}
                hidden={option.defaultHidden}
                selected={option.selected}
              >
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}
