// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Button, ButtonBox } from "../../components/common/Button/Button";

export function ServerError() {
  return (
    <>
      <meta name="robots" content="noindex" />
      <SiteLayout>
        <Heading1>Server Error</Heading1>
        <Text>
          サーバーで問題が発生しているため
          <br className="isOnlySp" />
          ページを表示できません。
          <br />
          しばらく時間を置いてからアクセスしてください。
        </Text>

        <ButtonBox marginTop="narrow">
          <Button as="a" href="/">
            マイページトップへ
          </Button>
        </ButtonBox>
      </SiteLayout>
    </>
  );
}
