import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

type ApiResponse = {
  url: string;
  error: string;
};

type FetchGmo = () => Promise<ApiResponse | undefined>;

// クレカ登録用URLの取得
export const gmoQuery = async ({
  userCarId,
  offerUid,
}: {
  userCarId?: string;
  offerUid?: string;
}) => {
  const options: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_API_V2_BASE_URL}/gmo/create_card_registrations_url`,
    method: "POST",
    params: { user_car_id: userCarId, offer_uid: offerUid },
  };

  const fetchGmo: FetchGmo = async () => {
    try {
      const res: AxiosResponse<ApiResponse> = await axios(options);
      return res.data;
    } catch (error) {
      toast("通信エラーが発生しました", { containerId: "error" });
      console.error(error);
    }
  };

  const gmoRes: ApiResponse | undefined = await fetchGmo();
  return { gmoRes };
};

// GMOカード登録画面へ遷移させる. 初回申込ページ(register/edit)でのみ使用.
export const transitGmoSiteByUid = async (offerUid: string) => {
  if (!offerUid) return null;
  const { gmoRes } = await gmoQuery({ offerUid });
  if (!gmoRes) return null;
  if (gmoRes.error) {
    toast("通信エラーが発生しました", { containerId: "error" });
    console.error(gmoRes.error);
    return null;
  }
  window.location.replace(gmoRes.url);
};

// GMOカード登録画面へ遷移させる.
export const transitGmoSiteByCarId = async (userCarId: string) => {
  if (!userCarId) return null;
  const { gmoRes } = await gmoQuery({ userCarId });
  if (!gmoRes) return null;
  if (gmoRes.error) {
    toast("通信エラーが発生しました", { containerId: "error" });
    console.error(gmoRes.error);
    return null;
  }
  window.location.replace(gmoRes.url);
};

export const useNoticeGmoResult = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const status = searchParams.get("status");
  let loading = true; // 下記1の実行を1度のみに制御(※ useHooks系だと更新が間に合わなかった)

  // 1. クレカ登録結果をtoastで出す
  useEffect(() => {
    if (type === "gmo" && loading) {
      if (status === "success") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        loading = false;
        toast("カード登録が成功しました", { containerId: "error" });
      } else if (status === "error") {
        loading = false;
        toast("カード登録が完了しておりません", { containerId: "error" });
      }
    }
  }, [status, type]);

  // 2. 1を実行後、クエリパラメータを削除するためリダイレクトさせる.
  useEffect(() => {
    if (!loading) navigate("/");
  }, [loading, navigate]);
};
