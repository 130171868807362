import { useMemo, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { validations } from "../../components/validates/validates";
// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Inner } from "../../components/common/Inner/Inner";
import { Button, ButtonBox } from "../../components/common/Button/Button";

// form
import { FormTitle, FormData } from "../../components/form/Form/Form";
import { InputText } from "../../components/form/InputText/InputText";

// style
import formStyle from "../../components/form/Form/Form.module.scss";
import { Message, ToastType } from "../../components/common/Message/Message";
import { ErrorText } from "../../components/form/ErrorText/ErrorText";

const API_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

type ValuesType = {
  email: undefined | string;
};

function PasswordForgetPage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ValuesType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const baseURL = useMemo(() => `${API_BASE_URL}/auth/password`, []);

  // トーストメッセージ用
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState<ToastType>("success");

  // 2重リクエスト防止用（特に画面表示しない予定）
  const loading = useRef(false);

  const handleOnSubmit: SubmitHandler<ValuesType> = (data: {
    email?: string;
  }) => {
    if (loading.current || !data || !data.email) {
      return;
    }
    loading.current = true;

    const params = {
      email: data.email,
      redirect_url: `${BASE_URL}/password/setting`,
    };
    const headers = {
      email: data.email,
      redirect_url: `${BASE_URL}/password/setting`,
    };

    axios
      .post(baseURL, params, { headers })
      .then(() => {
        setToastType("success");
        setToastMessage("メールを送信しました。");
      })
      // メール送信失敗時（404や500など）
      .catch((err) => {
        // アカウントの存在を特定させないために成功時と同じ処理
        setToastType("success");
        setToastMessage("メールを送信しました。");
      });
  };

  const goLogin = () => {
    loading.current = false;
    // ログイン画面に遷移させる
    navigate("/login");
  };

  return (
    <SiteLayout>
      {toastMessage && (
        <Message text={toastMessage} type={toastType} onClose={goLogin} />
      )}
      <Heading1>
        パスワードを
        <br className="isOnlySp" />
        お忘れの場合
      </Heading1>
      <Text>
        ご登録のメールアドレスを入力してください。
        <br />
        「メールを送信する」ボタンを押すとパスワード再設定用のURLをお送りします。
      </Text>

      <Inner size="wide">
        <form
          className={formStyle.form}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          {errors?.email && <ErrorText>{errors?.email?.message}</ErrorText>}
          <FormTitle title="メールアドレス" label={false} />
          <FormData>
            <InputText
              type="email"
              placeholder="example@ne.jp"
              name="email"
              register={register("email", {
                ...validations.required,
              })}
            />
          </FormData>

          <ButtonBox marginTop="narrow">
            <Button type="submit">メールを送信する</Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
}

export default PasswordForgetPage;
