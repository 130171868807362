import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Inner } from "../../components/common/Inner/Inner";
import { Button, ButtonBox } from "../../components/common/Button/Button";

// form
import {
  FormGroup,
  FormTitle,
  FormData,
} from "../../components/form/Form/Form";
import { InputPassword } from "../../components/form/InputText/InputText";

import formStyle from "../../components/form/Form/Form.module.scss";
import { Message, ToastType } from "../../components/common/Message/Message";
import { PasswordComplete } from "../../components/page/PasswordComplete/PasswordComplete";
import { usePasswordResetTokenCheck } from "../../components/hooks/usePasswordResetTokenCheck";
import { ServerError } from "../error/500";
import { ErrorText } from "../../components/form/ErrorText/ErrorText";
import { validations } from "../../components/validates/validates";

const API_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
type ValuesType = {
  password: undefined;
  password_confirmation: undefined; // APIに合わせる
};

function PasswordSettingPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ValuesType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const navigate = useNavigate();

  const { token: resetPasswordToken } = useParams();
  const tokenCheckQuery = usePasswordResetTokenCheck(resetPasswordToken || "");

  // トーストメッセージ用
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState<ToastType>("success");
  const [onToastClose, setOnToastClose] = useState<() => void>();
  const tid = useRef<NodeJS.Timeout>();

  // 2重リクエスト防止用（特に画面表示しない予定）
  const loading = useRef(false);

  // 完了画面切り替えフラグ
  const [succeeded, setSucceeded] = useState(false);

  const handleOnSubmit: SubmitHandler<ValuesType> = async (
    data: Partial<Record<"password" | "password_confirmation", string>>
  ) => {
    if (loading.current) return;
    loading.current = true;
    const baseURL = `${API_BASE_URL}/auth/password`;
    const params = {
      reset_password_token: resetPasswordToken,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    axios
      .put(baseURL, params, {})
      .then((res) => {
        const resData = res;
        if (!resData.data.success) {
          setToastType("error");
          setOnToastClose(() => navigate("/login"));
          setToastMessage("不正なアクセスです。\nログインページに遷移します。");
          return;
        }
        // エラーカウントリセット
        localStorage.setItem("errorCount", "0");

        setSucceeded(true);
        loading.current = false;

        // 5秒後にログインページに遷移（仕様）
        tid.current = setTimeout(() => navigate("/login"), 5000);
      })
      .catch((error) => {
        // 謎のエラー
        if (!axios.isAxiosError(error)) {
          return <ServerError />;
        }
        // ネットワークエラー
        if (!error.response) {
          return <ServerError />;
        }
        // 認証エラー
        if (error.response.status === 401) {
          navigate("/login");
          const errorData = error.response.data as { errors: string[] };
          toast(errorData.errors[0], { containerId: "error" });
          return null;
        }
        if (
          error.response.status === 400 &&
          Array.isArray(error.response.data)
        ) {
          // バリデーションエラー
          for (const { name, type, message } of error.response.data || []) {
            setError(
              name as keyof ValuesType,
              { type, message },
              { shouldFocus: true }
            );
          }
        }
        loading.current = false;
      });
  };

  useEffect(() => {
    // 「ログイン画面へ」ボタンなどで遷移した場合にTimerを削除する
    return tid.current ? () => clearTimeout(tid.current) : undefined;
  }, []);

  if (tokenCheckQuery.isError) {
    navigate("/login");
    return null;
  }

  return (
    <SiteLayout>
      {toastMessage && (
        <Message text={toastMessage} type={toastType} onClose={onToastClose} />
      )}
      {succeeded ? (
        <PasswordComplete />
      ) : (
        <>
          {/* TODO 別ファイルに切り出す */}
          <Heading1>パスワード再設定</Heading1>
          <Text>
            新しいパスワードを入力してください。
            <br />
            確認用に再度同じパスワードを入力してください。
          </Text>
          <Inner size="wide">
            <form
              className={formStyle.form}
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              {(errors?.password || errors?.password_confirmation) && (
                <ErrorText>
                  {(errors?.password?.message ===
                    errors?.password_confirmation?.message &&
                    errors?.password?.message) || (
                    <>
                      {errors?.password && errors?.password?.message}
                      {errors?.password && errors?.password_confirmation && (
                        <br />
                      )}
                      {errors?.password_confirmation &&
                        errors?.password_confirmation?.message}
                    </>
                  )}
                </ErrorText>
              )}
              <FormGroup>
                <FormTitle title="新しいパスワード" label={false} />
                <FormData>
                  <InputPassword
                    placeholder="Password"
                    name="password"
                    subText="半角英字の大文字小文字、半角数字を含んだ8文字以上255文字以内の値をご入力ください"
                    register={register("password", {
                      ...validations.required,
                    })}
                  />
                </FormData>
              </FormGroup>

              <FormGroup>
                <FormTitle title="新しいパスワード（確認用）" label={false} />
                <FormData>
                  <InputPassword
                    placeholder="Password"
                    name="password_confirmation"
                    register={register("password_confirmation", {
                      ...validations.required,
                    })}
                  />
                </FormData>
              </FormGroup>

              <ButtonBox>
                <Button type="submit">設定する</Button>
              </ButtonBox>
            </form>
          </Inner>
        </>
      )}
    </SiteLayout>
  );
}

export default PasswordSettingPage;
