import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { Session } from "./useSession";

export const useInquiryDataQuery = (session: Session | null) => {
  type ApiResponse = {
    subjects: { [key: string]: string };
    email: string;
    name: string;
    user_cars: [
      {
        user_car_id: number;
        vehicle_type: string;
        number: string;
        created_at: string;
      }
    ];
  };
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/inquiry`;
  const fetcher = async () => {
    const res = await axios.get<ApiResponse>(url, {
      headers: {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      },
    });
    return res.data;
  };
  return useQuery<ApiResponse, AxiosError>([url], fetcher, {
    enabled: session != null,
    staleTime: 60 * 60 * 1000,
  });
};
