// import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./ProxyInputAgreement.module.scss";

import { InnerTextArea } from "../common/InnerTextArea/InnerTextArea";

export function ProxyInputAgreement() {
  const contents = [
    "必ずお客さまご自身が同意条項をご確認いただいたうえでご入力ください。",
    "本システムにご入力いただいた個人情報は、プレミアワランティサービス株式会社が提供する「カープレミア安心メンテパック」における販売担当者さまによる代理入力に使用するものとし、それ以外の目的では使用しません。",
    "本書面は、前記の目的にのみ使用され、何らかの契約の成立を証する書面ではありません。",
    "販売担当者さまによる代理入力にあたっては、お客さまご自身に「カープレミア安心メンテパック」内の「申込情報入力」画面上の「プライバシーポリシー」の内容を確認のうえ同意いただいてください。",
  ];

  return (
    <InnerTextArea>
      <div className={clsx(style.proxy_input)}>
        <h1>代理入力規約</h1>
        <ul>
          {contents.map((content, index) => (
            <li key={content} className={style.list}>
              <span>{index + 1}.</span>
              {content}
            </li>
          ))}
        </ul>
      </div>
    </InnerTextArea>
  );
}
