// style
import clsx from "clsx";
import style from "./Textarea.module.scss";

// plugin

// テキストボックス
type TextareaProps = {
  name: string;
  placeholder: string;

  // エラー
  error?: {
    message?: string; // これを表示
  };
  errors?: object;
  register?: object;
  errorsMessage?: string;
};

export function Textarea({
  name,
  placeholder,
  error,
  errors,
  register,
  errorsMessage,
}: TextareaProps) {
  return (
    <>
      {(error || errors) && (error?.message || errorsMessage) && (
        <p className={style.errorText}>{error?.message || errorsMessage}</p>
      )}

      <div className={style.wrapper}>
        <textarea
          name={name}
          placeholder={placeholder}
          className={clsx(style.textarea, (error || errors) && style.isError)}
          {...register}
        />
      </div>
    </>
  );
}
