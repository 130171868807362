import { ReactNode, Dispatch, SetStateAction } from "react";

// style
import ReactModal from "react-modal";
import style from "./Modal.module.scss";

// plugin

// hooks
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
};

export function Modal({ isOpen, setIsOpen, children }: Props) {
  // スクロール禁止解除
  const [{ handleUnLockBodyScroll }] = useLockBodyScroll();

  // モーダル閉じる関数
  const closeModal = () => {
    setIsOpen(false);
    handleUnLockBodyScroll();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName={style.overlay}
      className={style.modal}
      bodyOpenClassName={style.body}
      ariaHideApp={false}
    >
      <button type="button" className={style.close} onClick={closeModal}>
        閉じる
      </button>

      {children}
    </ReactModal>
  );
}
