import axios, { AxiosError } from "axios";
import {
  QueryClient,
  QueryFunction,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { OfferSession } from "./useOfferSession";

const url = `${process.env.REACT_APP_API_V2_BASE_URL}/offer`;

export type Offer = {
  name1: string | null;
  name2: string | null;
  name_kana1: string | null;
  name_kana2: string | null;
  phone: string | null;
  tel: string | null;
  fax: string | null;
  zip: string | null;
  pref: string | null;
  addr: string | null;
  addr_other: string | null;
  addr_ap: string | null;
  email: string | null;
  contract_type: string | null;
  payment_type: string | null;
  r_policy_flg: boolean | null;
  p_policy_flg: boolean | null;
  user_policy_flg: boolean | null;
  proxy_input_agree_flag: boolean | null;
  status: string | null;
};

export const useOfferQuery = (
  session: OfferSession | null,
  options?: Omit<UseQueryOptions<Offer, AxiosError>, "queryKey" | "queryFn">
) => {
  const fetcher: QueryFunction<Offer> = async () => {
    const { data } = await axios.get<Offer>(url, {
      headers: {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      },
    });
    return data;
  };
  return useQuery<Offer, AxiosError>([url], fetcher, {
    enabled: session != null,
    ...options,
  });
};

export const useUpdateOfferMutation = () => {
  type Variables = { session: OfferSession; values: Offer };
  type Error = Record<"name" | "type" | "message", string>;
  return useMutation<unknown, AxiosError<Error[]>, Variables>(
    [url],
    ({ session, values }) => {
      return axios.patch(url, values, {
        headers: {
          "access-token": session.accessToken,
          client: session.client,
          uid: session.uid,
        },
      });
    }
  );
};

export const invalidateOffer = (c: QueryClient) => {
  c.invalidateQueries([url]);
};
