import { ReactNode, createElement } from "react";

// style
import clsx from "clsx";
import style from "./Button.module.scss";

// plugin

type Tags = "button" | "a";

type Props<T extends Tags> = JSX.IntrinsicElements[T] & {
  as?: T;
  children: ReactNode;
  onClick?: () => void;
  color?: "primary" | "whiteBase" | "whiteGray";
  size?: "middle" | "small";
  disabled?: boolean | string;
  disabledColor?: boolean; // disabled時にgrayにしたいとき

  // トグル用
  toggleIcon?: boolean;
  toggleFlg?: boolean;
};

export const Button = <T extends Tags = "button">({
  as,
  children,
  onClick,
  color = "primary",
  size = "middle",
  disabled = false,
  toggleIcon = false,
  toggleFlg = false,
  disabledColor = false,
  ...props
}: Props<T>) => {
  // 色
  let colorStyle: string[] | string = "";
  if (color === "whiteBase") {
    colorStyle = style.isWhiteBase;
  } else if (color === "whiteGray") {
    colorStyle = style.isWhiteGray;
  }

  const button = createElement(
    as || "button",
    {
      disabled,
      ...props,
      className: clsx(
        style.button,
        colorStyle,
        disabledColor && style.disabled,
        size === "small" && style.isSmall,
        toggleIcon && style.isToggleIcon,
        toggleFlg && style.isOpen
      ),
      onClick,
    },
    children
  );

  return button;
};

type ButtonBoxProps = {
  children: ReactNode;
  marginTop?: "normal" | "narrow" | "tiny";
};

export function ButtonBox({ children, marginTop = "normal" }: ButtonBoxProps) {
  return (
    <div className={clsx(style.box, marginTop === "narrow" && style.isNarrow)}>
      {children}
    </div>
  );
}
