import axios from "axios";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const usePrefecturesQuery = () => {
  type ApiResponse = {
    status: boolean;
    values: { id: number; name: string }[];
  };
  const url = `${process.env.REACT_APP_API_BASE_URL}/constant/pref`;
  const fetcher: QueryFunction<ApiResponse> = async () => {
    const res = await axios.get<ApiResponse>(url);
    return res.data;
  };
  return useQuery([url], fetcher, { staleTime: 60 * 60 * 1000 });
};

// components/form/Select/Select の options に渡す値
export const usePrefectureOptions = () => {
  const query = usePrefecturesQuery();
  const prefectures = useMemo(() => {
    if (!query.data) return [];
    return query.data.values.map(({ id, name }) => ({
      text: name,
      value: name,
      index: id,
    }));
  }, [query.data]);
  return { query, prefectures };
};
