import { ReactNode } from "react";

// style
import clsx from "clsx";
import style from "./SiteLayout.module.scss";

// layout
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

// plugin

type Props = {
  children: ReactNode;
  size?: "narrow" | "wide";
  refleshInquiry?: () => void;
  contractType?: string;
};

export function SiteLayout({
  children,
  size = "narrow",
  contractType,
  refleshInquiry,
}: Props) {
  return (
    <div className={style.outer}>
      <Header refleshInquiry={refleshInquiry} contractType={contractType} />
      <div className={style.wrapper}>
        <main className={clsx(style.main, size === "wide" && style.isWide)}>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
}
