// スクロール禁止/禁止解除の処理
export function useLockBodyScroll() {
  const position = window.scrollY;

  const handleLockBodyScroll = () => {
    document.body.style.overflow = "hidden";
    document.body.style.top = `${(position * -1).toString()}px`;
  };
  const handleUnLockBodyScroll = () => {
    document.body.style.overflow = "visible";
    window.scrollTo(0, position);
  };

  return [{ handleLockBodyScroll, handleUnLockBodyScroll }];
}
