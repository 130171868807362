import axios from "axios";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const usePaymentTypeQuery = () => {
  type ApiResponse = {
    status: boolean;
    values: { id: number; key: string; name: string }[];
  };
  const url = `${process.env.REACT_APP_API_BASE_URL}/constant/payment_types`;
  const fetcher: QueryFunction<ApiResponse> = async () => {
    const res = await axios.get<ApiResponse>(url);
    return res.data;
  };
  return useQuery([url], fetcher, { staleTime: 60 * 60 * 1000 });
};

export const usePaymentTypes = () => {
  const query = usePaymentTypeQuery();
  const paymentTypes = useMemo(() => {
    if (!query.data) return [];
    return query.data.values.map(({ key, name }) => ({
      label: name,
      value: key,
      key,
    }));
  }, [query.data]);
  return { query, paymentTypes };
};
