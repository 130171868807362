// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Button, ButtonBox } from "../../components/common/Button/Button";

export function NotFound() {
  return (
    <>
      <meta name="robots" content="noindex" />
      <SiteLayout>
        <Heading1>Page Not Found</Heading1>
        <Text>
          大変申し訳ございませんが、
          <br className="isOnlySp" />
          お探しのページは、
          <br className="isOnlyPc" />
          削除されたか、
          <br className="isOnlySp" />
          URLが間違っている可能性があります。
          <br />
          アドレスを確認してください。
        </Text>

        <ButtonBox marginTop="narrow">
          <Button as="a" href="/">
            マイページトップへ
          </Button>
        </ButtonBox>
      </SiteLayout>
    </>
  );
}
