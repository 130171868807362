import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { ErrorText } from "../../components/form/ErrorText/ErrorText";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

const useConfirmMutation = () => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/email`;
  return useMutation<
    unknown,
    AxiosError<{ message: string }[]>,
    { confirmation_token: string }
  >([url], async (data) => {
    return axios.patch(url, data);
  });
};

export default function UserInfoEmailConfirm() {
  const { token } = useParams();
  const mutation = useConfirmMutation();

  useEffect(() => {
    if (!mutation.isIdle) return;
    if (!token) return;
    // StrictMode だと 2 連打してしまうので開発時は少し待つ
    const t = setTimeout(
      () => {
        mutation.mutate({ confirmation_token: token });
      },
      process.env.NODE_ENV === "development" ? 30 : 0
    );
    return () => clearTimeout(t);
  }, [mutation, token]);

  // できた
  if (mutation.isSuccess) return <Confirmed />;
  // エラー
  if (mutation.isError) {
    const messages = (mutation.error.response?.data || []).map(
      (e) => e.message
    );
    return <Error messages={messages} />;
  }
  // API 実行中
  return <Confirming />;
}

const Confirming = () => {
  return (
    <SiteLayout>
      <Heading1>
        メールアドレス
        <br className="isOnlySp" />
        変更確認
      </Heading1>
      <Text>
        <progress>確認中……</progress>
      </Text>

      <ButtonBox marginTop="narrow">
        <Button as="a" href="/login">
          ログインページへ
        </Button>
      </ButtonBox>
    </SiteLayout>
  );
};

const Confirmed = () => (
  <SiteLayout>
    <Heading1>
      メールアドレス
      <br className="isOnlySp" />
      変更完了
    </Heading1>
    <Text>メールアドレスの変更が完了しました。</Text>
    <Text>
      ログイン用のメールアドレスも
      <br className="isOnlySp" />
      変更されましたのでご注意ください
    </Text>

    <ButtonBox marginTop="narrow">
      <Button as="a" href="/login">
        ログインページへ
      </Button>
    </ButtonBox>
  </SiteLayout>
);

const Error = ({ messages }: { messages: string[] }) => (
  <SiteLayout>
    <Heading1>
      メールアドレス
      <br className="isOnlySp" />
      変更確認
    </Heading1>
    {messages.map((m) => (
      <ErrorText key={m}>{m}</ErrorText>
    ))}

    <ButtonBox marginTop="narrow">
      <Button as="a" href="/userinfo/email">
        メールアドレス入力へ
      </Button>
    </ButtonBox>
  </SiteLayout>
);
