import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useRef } from "react";

export type OfferSession = {
  accessToken: string;
  client: string;
  expiry: string;
  uid: string;
};

export const useOfferSession = (): OfferSession | null => {
  const s = useRef(loadLocalStorage());
  return s.current;
};

export const useOfferSignInMutation = () => {
  type Params = { uid: string; password: string };
  type Error = AxiosError<{ success: false; errors: string[] }>;
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/auth/offer/sign_in`;
  return useMutation<OfferSession, Error, Params>([url], async (params) => {
    const res = await axios.post<OfferSession>(url, params);
    const h = res.headers;
    const session = {
      accessToken: h["access-token"],
      client: h.client,
      expiry: h.expiry,
      uid: h.uid,
    };
    saveOfferSession(session);
    return session;
  });
};

export const offerSignOut = () => {
  saveOfferSession(null);
};

const storageKey = "offerSession";

const saveOfferSession = (session: OfferSession | null): void => {
  if (!session) {
    delete localStorage[storageKey];
    return;
  }
  localStorage[storageKey] = JSON.stringify(session);
};

const loadLocalStorage = (): OfferSession | null => {
  const load = () => {
    if (!localStorage[storageKey]) return null;
    try {
      return JSON.parse(localStorage[storageKey]) as OfferSession;
    } catch {
      return null;
    }
  };
  const session = load();
  if (!session) return null;

  // 必要な値があれば良し
  for (const key of ["client", "uid", "accessToken", "expiry"] as const) {
    if (!session[key]) return null;
  }
  return session;
};
