// node_modules
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";

// 型import
import { Settlement } from "../types/settlement";
import { useSession } from "./useSession";

type ApiResponse = {
  billed_time: string;
  total_billing_time: string;
  pay_date: string;
  price: string;
};

const useSettlementQuery = (carId: string) => {
  const session = useSession();

  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/settlement/next_record?carId=${carId}`;
  const fetcher = async () => {
    const res = await axios.get<ApiResponse>(url, {
      headers: {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      },
    });
    return res.data;
  };
  return useQuery<ApiResponse, AxiosError>([url], fetcher, {
    enabled: session != null,
    staleTime: 60 * 60 * 1000,
  });
};

export const useSettlement = (carId: string) => {
  const query = useSettlementQuery(carId);
  const { data } = query;

  const { settlement } = useMemo(() => {
    if (!data) return { settlement: null, name: "" };
    const nextSettlement: Settlement = data;
    return { settlement: nextSettlement };
  }, [data]);

  return { settlement, data, query };
};
