import { ReactNode } from "react";

// style
import style from "./TextAttention.module.scss";

type TextAttentionProps = {
  list: {
    item: string;
  }[];
};

export function TextAttention({ list }: TextAttentionProps) {
  return (
    <div className={style.wrapper}>
      <ul className={style.list}>
        {list.map((lists) => {
          return (
            <li className={style.item} key={lists.item}>
              {lists.item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
