// node_modules
import axios from "axios";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LoggedInFlagContext } from "../providers/LoggedInFlagProvider";
import { useSession } from "./useSession";

export const useLoginCheck = async (redirectPath?: string) => {
  const session = useSession();
  const navigate = useNavigate();
  const { setLoggedIn } = useContext(LoggedInFlagContext);
  const loading = useRef(false);

  useEffect(() => {
    if (!session) {
      navigate("/login");
    }
  }, [navigate, session]);

  useEffect(() => {
    if (!session) return;
    if (loading.current) return;
    loading.current = true;

    const fetchFunc = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/sessions`,
        {
          headers: {
            "access-token": session.accessToken,
            client: session.client,
            uid: session.uid,
          },
        }
      );
      const isLoggedIn = res.data.is_login;
      setLoggedIn(isLoggedIn);
      if (!isLoggedIn) {
        // 未ログインであればログイン画面へリダイレクト
        navigate("/login");
      }
      // ログイン済みでかつ遷移先Pathがあれば対象ページへ遷移させる
      if (isLoggedIn && redirectPath) {
        navigate(redirectPath);
      }
    };
    fetchFunc();
  }, [navigate, setLoggedIn, session, redirectPath]);
};
