import { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Inner } from "../../components/common/Inner/Inner";
import { Button, ButtonBox } from "../../components/common/Button/Button";

// form
import {
  FormGroup,
  FormTitle,
  FormData,
} from "../../components/form/Form/Form";
import {
  InputText,
  InputPassword,
} from "../../components/form/InputText/InputText";
import { ErrorText } from "../../components/form/ErrorText/ErrorText";

import formStyle from "../../components/form/Form/Form.module.scss";
import style from "../../css/Login.module.scss";
import { useSetSession } from "../../components/hooks/useSession";
import { sessionType } from "../../components/types/sessionType";
import { LoggedInFlagContext } from "../../components/providers/LoggedInFlagProvider";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";

const signIn = async ({
  email,
  password,
}: Record<"email" | "password", string>) => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/auth/sign_in`;
  try {
    const res = await axios.post<{ data: sessionType }>(url, {
      email,
      password,
    });
    const h = res.headers;
    return {
      accessToken: h["access-token"],
      client: h.client,
      expiry: h.expiry,
      uid: h.uid,
      user: res.data.data,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response) {
      console.debug("e.response", e.response);
      const res: AxiosResponse<{ success: false; errors: string[] }> =
        e.response;
      return { errors: res.data.errors };
    }
    throw e;
  }
};

function Login() {
  // ヘッダー用のログインフラグを折る
  const { setLoggedIn } = useContext(LoggedInFlagContext);
  useEffect(() => setLoggedIn(false), [setLoggedIn]);

  const setSession = useSetSession();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  // 画面遷移用のメソッド
  const navigate = useNavigate();

  // ログイン失敗回数
  const [errorCount, setErrorCount] = useState(
    parseInt(localStorage.getItem("errorCount") || "0", 10)
  );
  // const [errorMessage, setErrorMessage] = useState("");

  const handleOnSubmit = async (values: {
    email: string;
    password: string;
  }) => {
    const x = await signIn(values);
    if (x.errors) {
      const count = errorCount + 1;
      localStorage.setItem("errorCount", count.toString());
      // setErrorMessage(x.errors.join(", "));
      setErrorCount(count);
    } else {
      localStorage.setItem("errorCount", "0");
      setSession(x);
      navigate("/");
    }
  };

  // エラー内容とエラー回数
  const error = {
    type: "",
    count: errorCount,
  };

  // ログイン済みであればTopページに飛ばす
  useLoginCheck("/");

  return (
    <SiteLayout>
      <Heading1>ログイン</Heading1>
      <Text>ログインIDとパスワードを入力してください</Text>

      <Inner size="wide">
        <form
          className={formStyle.form}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          {error && error.count !== 0 && (
            <ErrorText>
              {error.count < 5 ? (
                <span>
                  ログインIDまたはパスワードに誤りがあります。
                  <br />
                  あと{5 - errorCount}回間違えるとログインが制限されます
                </span>
              ) : (
                <span>
                  規定回数以上間違えたため、ログインを制限しております。
                  <br />
                  パスワードを忘れた場合は
                  <a href="/password/forget">こちら</a>
                  より再度設定してください。
                </span>
              )}
            </ErrorText>
          )}

          <FormGroup>
            <FormTitle title="ログインID" label={false} />
            <FormData>
              <InputText
                placeholder="ID"
                name="id"
                register={register("email", {
                  required: true,
                })}
                errors={errors.email}
                errorsMessage="値が入力されていません"
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="パスワード" label={false} />
            <FormData>
              <InputPassword
                placeholder="Password"
                name="password"
                register={register("password", {
                  required: true,
                })}
                errors={errors.password}
                errorsMessage="値が入力されていません"
              />
            </FormData>
          </FormGroup>

          <ButtonBox>
            {error.count < 5 ? (
              <Button type="submit">ログイン</Button>
            ) : (
              <Button disabled>ログイン</Button>
            )}
          </ButtonBox>

          <div className={style.linkWrap}>
            <a href="/password/forget" className={style.link}>
              パスワードを忘れてしまった方はこちら
            </a>
          </div>
        </form>
      </Inner>
    </SiteLayout>
  );
}

export default Login;
