import { useSearchParams } from "react-router-dom";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { PremiumContents } from "./premium_contents";
import { CarSensorContents } from "./car_sensor_contents";

export function Contract() {
  const [searchParams] = useSearchParams();
  return (
    <SiteLayout>
      {searchParams.get("type") === "car_sensor" ? (
        <CarSensorContents />
      ) : (
        <PremiumContents />
      )}
    </SiteLayout>
  );
}
