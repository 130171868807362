import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import {
  FormData,
  FormGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import formStyle from "../../components/form/Form/Form.module.scss";
import { InputText } from "../../components/form/InputText/InputText";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import {
  Session,
  useSession,
  useSetSession,
} from "../../components/hooks/useSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { validations } from "../../components/validates/validates";

export default function UserInfoEmailEdit() {
  const [completed, setCompleted] = useState(false);
  if (completed) {
    return <Sent />;
  }
  return (
    <Edit
      onComplete={() => {
        setCompleted(true);
      }}
    />
  );
}

const useEmailMutation = () => {
  const url = `${process.env.REACT_APP_API_V2_BASE_URL}/email`;
  type Variables = { session: Session; values: { email: string } };

  return useMutation<
    unknown,
    AxiosError<Record<"name" | "type" | "message", string>[]>,
    Variables
  >([url], async ({ session, values }) => {
    return axios.post(url, values, {
      headers: {
        "access-token": session.accessToken,
        client: session.client,
        uid: session.uid,
      },
    });
  });
};

const Edit = ({ onComplete }: { onComplete: () => void }) => {
  useLoginCheck();
  const session = useSession();
  const setSession = useSetSession();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: { email: "" },
  });
  const mutation = useEmailMutation();

  if (!session) return null;

  const onSubmit: Parameters<typeof handleSubmit>[0] = (values) => {
    console.debug("submit", values);
    if (mutation.isLoading) return;

    mutation.mutate(
      { session, values },
      {
        onSuccess() {
          window.scrollTo(0, 0);
          onComplete();
          setSession(null);
        },
        onError(e) {
          if (!axios.isAxiosError(e) || !e.response?.data) {
            toast("システムエラーが発生しました", { containerId: "error" });
            return;
          }
          for (const { name, message } of e.response.data) {
            setError(
              name as Parameters<typeof setError>[0],
              { message },
              { shouldFocus: true }
            );
          }
        },
      }
    );
  };

  return (
    <SiteLayout>
      <Heading1>
        メールアドレス
        <br className="isOnlySp" />
        変更
      </Heading1>
      <Text>ご変更後のメールアドレスを入力してください。</Text>
      <Inner size="wide">
        <form className={formStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormTitle title="メールアドレス" />
            <FormData>
              <InputText
                name="email"
                type="email"
                placeholder="mail@example.com"
                register={register("email", { ...validations.required })}
                error={errors.email}
              />
            </FormData>
          </FormGroup>
          <ButtonBox>
            <Button type="submit">メールを送信する</Button>
            <Button as="a" href="/userinfo/summary" color="whiteBase">
              戻る
            </Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
};

const Sent = () => {
  return (
    <SiteLayout>
      <Heading1>
        メールアドレス
        <br className="isOnlySp" />
        変更
      </Heading1>
      <Text>
        まだメールアドレスの変更は
        <br className="isOnlySp" />
        完了しておりません
      </Text>
      <Text>
        ご入力いただいたメールアドレス宛に
        <br className="isOnlySp" />
        確認用メールを送信しました。
      </Text>
      <Text>
        届きましたメールに記載のURLに
        <br className="isOnlySp" />
        アクセスすると変更が完了します
      </Text>

      <ButtonBox marginTop="narrow">
        <Button as="a" href="/userinfo/edit">
          会員情報編集画面へ
        </Button>
      </ButtonBox>
    </SiteLayout>
  );
};
