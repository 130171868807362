import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sessionType as User } from "../types/sessionType";
import { useOfferSession } from "./useOfferSession";

export type Session = {
  accessToken: string;
  client: string;
  expiry: string;
  uid: string;
  user: User;
};

const SessionContext = createContext<{
  session: Session | null;
  setSession: (s: Session | null) => void;
}>({ session: null, setSession: () => null });
SessionContext.displayName = "SessionContext";

export const SessionProvider = ({ children }: { children?: ReactNode }) => {
  // localStorage から初期値を取得する
  const [session, setSession] = useState<Session | null>(loadLocalStorage());
  const value = useMemo(() => ({ session, setSession }), [session, setSession]);
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

// セッションを得る
export const useSession = () => {
  return useContext(SessionContext).session;
};

// セッションを更新する
export const useSetSession = () => {
  // localStorage にも保存する
  const { setSession } = useContext(SessionContext);
  return (s: Session | null) => {
    setSession(s);
    saveLocalStorage(s);
  };
};

// localStorage にセッションを保存する
const saveLocalStorage = (s: Session | null) => {
  if (!s) {
    // nullの場合はlocalStorageのデータを削除
    localStorage.removeItem("session");
    return;
  }
  localStorage.session = JSON.stringify(s);
};

const loadLocalStorage = (): Session | null => {
  const load = () => {
    if (!localStorage.session) return null;
    try {
      return JSON.parse(localStorage.session) as Session;
    } catch {
      return null;
    }
  };
  const session = load();
  if (!session) return null;

  // 必要な値があれば良し
  for (const key of [
    "client",
    "uid",
    "accessToken",
    "expiry",
    "user",
  ] as const) {
    if (!session[key]) return null;
  }
  return session;
};

export const useGoToLogin = (uid: string | null, message: string) => {
  const navigate = useNavigate();
  const session = useOfferSession();
  useEffect(() => {
    if (!session || session.uid !== uid) {
      if (uid) {
        toast(message, { containerId: "error" });
        navigate(`/register/login/${uid}`);
      } else {
        toast(message, { containerId: "error" });
        navigate(`/login`);
      }
    }
  }, [uid, navigate, session, message]);
};

export const useSessionTimedOut = (uid: string | null) => {
  useGoToLogin(uid, "セッションが切れました");
};
