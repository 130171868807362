import axios from "axios";

import { useContext, useEffect, useRef, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../components/common/Heading/Heading";
import { Text } from "../../components/common/Text/Text";
import { Inner } from "../../components/common/Inner/Inner";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { TextAttention } from "../../components/common/TextAttention/TextAttention";

// style
import formStyle from "../../components/form/Form/Form.module.scss";
import style from "./edit.module.scss";

// mypage
import {
  MaintenanceSchedule,
  MaintenanceScheduleItem,
} from "../../components/mypage/MaintenanceSchedule/MaintenanceSchedule";

// form
import {
  FormGroup,
  FormTitle,
  FormData,
} from "../../components/form/Form/Form";
import { InputDate } from "../../components/form/InputDate/InputDate";
import { SelectTime } from "../../components/form/SelectTime/SelectTime";

// hook
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { useSession } from "../../components/hooks/useSession";
import { useUserCars } from "../../components/hooks/useUserCars";
import {
  useUserMaintenance,
  invalidateUserMaintenance,
} from "../../components/hooks/useUserMaintenance";
import { SelectedUserCarIdContext } from "../../components/providers/SelectedUserCarIdProvider";

const API_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;

function MaintenanceScheduleEdit() {
  // ログインチェック
  useLoginCheck();

  const queryClient = useQueryClient();
  const session = useSession();
  const loading = useRef(false);

  // 車両情報
  const { carId } = useUserCars();

  // クエリから車両IDを取得
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryCarId = query.get("carId");

  // クエリがなければ、APIで取得してきた車両Idを渡す.
  const selectedCarId = queryCarId || carId;

  const { setSelectedUserCarId } = useContext(SelectedUserCarIdContext);
  useEffect(
    () => setSelectedUserCarId(selectedCarId),
    [selectedCarId, setSelectedUserCarId]
  );

  // メンテナンス情報
  const { nextMaintenance, query: maintenanceQuery } =
    useUserMaintenance(selectedCarId);
  const navigate = useNavigate();

  useEffect(() => {
    if (nextMaintenance && nextMaintenance.status !== "予定") {
      navigate("/");
    }
    if (maintenanceQuery && !maintenanceQuery.isLoading && !nextMaintenance) {
      navigate("/");
    }
  }, [navigate, nextMaintenance, maintenanceQuery]);

  const openTime = nextMaintenance?.maintenanceShop?.openTime;
  const closeTime = nextMaintenance?.maintenanceShop?.closeTime;

  // startHourを切り出し
  const startHourString = openTime && String(openTime).substring(0, 2);
  const startHour = Number(startHourString);

  // startMinutesを切り出し
  const startMinutesString = openTime && String(openTime);
  const startMinutesBack =
    startMinutesString &&
    startMinutesString.substring(startMinutesString.length - 2);
  const startMinutes = Number(startMinutesBack);

  // endHourを切り出し
  const endHourString = closeTime && String(closeTime).substring(0, 2);
  const endHour = Number(endHourString);

  // endMinutesを切り出し
  const endMinutesString = closeTime && String(closeTime);
  const endMinutesBack =
    endMinutesString && endMinutesString.substring(endMinutesString.length - 2);
  const endMinutes = Number(endMinutesBack);

  type ValuesType = {
    date1: string | Date;
    date2: string | Date;
    date3: string | Date;
    time1: string | Date;
    time2: string | Date;
    time3: string | Date;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    control,
    reset,
  } = useForm<ValuesType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const [bool, setBool] = useState(true); // これは、一度しか実行されないための条件
  useEffect(() => {
    if (
      nextMaintenance?.schedule_datetime1 &&
      nextMaintenance?.schedule_datetime2 &&
      nextMaintenance?.schedule_datetime3 &&
      bool
    ) {
      setBool(false);
      reset({
        date1: new Date(nextMaintenance.schedule_datetime1),
        date2: new Date(nextMaintenance.schedule_datetime2),
        date3: new Date(nextMaintenance.schedule_datetime3),
        time1: new Date(nextMaintenance.schedule_datetime1),
        time2: new Date(nextMaintenance.schedule_datetime2),
        time3: new Date(nextMaintenance.schedule_datetime3),
      });
    }
  }, [reset, nextMaintenance, bool]);

  const isTimeValidation = (date: Date | string) => {
    if (typeof date === "string") return undefined;

    // 時刻だけで比較したいので、年月日は本日の日付に修正
    const value = new Date();
    value.setHours(date.getHours(), date.getMinutes());

    const startDate = new Date();
    startDate.setHours(startHour, startMinutes);

    const endDate = new Date();
    endDate.setHours(endHour, endMinutes);

    if (value < startDate || endDate < value) {
      return "営業時間内から選択してください";
    }
  };

  // フォームsubmit時
  const handleOnSubmit: SubmitHandler<ValuesType> = (val: ValuesType) => {
    // ボタン2度押し防止
    if (loading.current) return;
    loading.current = true;

    const params = {
      maintenance_result_id: nextMaintenance?.maintenance_result_id,
      date1: val.date1,
      date2: val.date2,
      date3: val.date3,
      time1: val.time1,
      time2: val.time2,
      time3: val.time3,
    };

    const headers = {
      "access-token": session?.accessToken || "",
      client: session?.client || "",
      uid: session?.uid || "",
    };

    const baseURL = `${API_BASE_URL}/maintenances/edit_schedule`;

    axios
      .post(baseURL, params, { headers })
      .then(() => {
        clearErrors();
        loading.current = false;
        invalidateUserMaintenance(queryClient);
        navigate("/");
      })
      .catch((err) => {
        for (const { name: attrName, type, message } of err.response.data ||
          []) {
          setError(
            attrName as keyof ValuesType,
            { type, message },
            { shouldFocus: true }
          );
        }

        loading.current = false;
      });
  };

  return (
    <SiteLayout>
      <Heading1>
        メンテナンス予約
        <br className="isOnlySp" />
        候補日選択
      </Heading1>
      <Text marginBottom="narrow">
        予約したい日程を選択し、予約申請するボタンを押してください。
      </Text>
      <TextAttention
        list={[
          { item: "予約申請では予約は成立していません。" },
          { item: "ご登録の連絡先へ整備工場から連絡が来ます。" },
          { item: "整備工場との打ち合わせ後に予約が成立となります。" },
        ]}
      />

      {nextMaintenance && (
        <Inner>
          <form
            className={formStyle.form}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <MaintenanceSchedule
              date={nextMaintenance.date}
              dateTitle="基準日"
              direction="vertical"
            >
              <MaintenanceScheduleItem
                title="整備工場名"
                data={nextMaintenance.maintenanceShop.name}
              />
              <MaintenanceScheduleItem
                title="メンテナンス内容"
                data={nextMaintenance.detail}
              />
            </MaintenanceSchedule>
            <FormGroup>
              <FormTitle title="第一候補　日付" label />
              <FormData>
                <InputDate
                  name="date1"
                  startDate={nextMaintenance.date}
                  placeholder="選択してください"
                  error={errors.date1}
                  control={control}
                  register={register("date1", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                  })}
                />
              </FormData>
              <span className={style.caution}>
                予約候補日は本日から2週間以降の日にちをご選択ください。
              </span>
            </FormGroup>
            <FormGroup>
              <FormTitle title="第一候補　時間" label />
              <FormData>
                <SelectTime
                  name="time1"
                  startHour={startHour}
                  startMinutes={startMinutes}
                  endHour={endHour}
                  endMinutes={endMinutes}
                  error={errors.time1}
                  control={control}
                  register={register("time1", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                    validate: isTimeValidation,
                  })}
                />
              </FormData>
            </FormGroup>
            <FormGroup>
              <FormTitle title="第二候補　日付" label />
              <FormData>
                <InputDate
                  name="date2"
                  startDate={nextMaintenance.date}
                  placeholder="選択してください"
                  error={errors.date2}
                  control={control}
                  register={register("date2", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                  })}
                />
              </FormData>
              <span className={style.caution}>
                予約候補日は本日から2週間以降の日にちをご選択ください。
              </span>
            </FormGroup>
            <FormGroup>
              <FormTitle title="第二候補　時間" label />
              <FormData>
                <SelectTime
                  name="time2"
                  startHour={startHour}
                  startMinutes={startMinutes}
                  endHour={endHour}
                  endMinutes={endMinutes}
                  error={errors.time2}
                  control={control}
                  register={register("time2", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                    validate: isTimeValidation,
                  })}
                />
              </FormData>
            </FormGroup>
            <FormGroup>
              <FormTitle title="第三候補　日付" label />
              <FormData>
                <InputDate
                  name="date3"
                  startDate={nextMaintenance.date}
                  placeholder="選択してください"
                  error={errors.date3}
                  control={control}
                  register={register("date3", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                  })}
                />
              </FormData>
              <span className={style.caution}>
                予約候補日は本日から2週間以降の日にちをご選択ください。
              </span>
            </FormGroup>
            <FormGroup>
              <FormTitle title="第三候補　時間" label />
              <FormData>
                <SelectTime
                  name="time3"
                  startHour={startHour}
                  startMinutes={startMinutes}
                  endHour={endHour}
                  endMinutes={endMinutes}
                  error={errors.time3}
                  control={control}
                  register={register("time3", {
                    required: {
                      value: true,
                      message: "値が入力されていません",
                    },
                    validate: isTimeValidation,
                  })}
                />
              </FormData>
            </FormGroup>
            <ButtonBox>
              <Button type="submit">予約申請する</Button>
              <Button as="a" href="/" color="whiteBase">
                マイページトップに戻る
              </Button>
            </ButtonBox>
          </form>
        </Inner>
      )}
    </SiteLayout>
  );
}

export default MaintenanceScheduleEdit;
